import React, {Children, Fragment, useEffect, useState} from "react"
import {connect} from "react-redux"
import PropTypes from "prop-types"

const Default = ({children}) => {
	return (
		<Fragment>
			<section className="h-full sm:p-20 min-h-screen">
				<div className="flex sm:px-6 sm:py-8 mx-auto w-full justify-center">
					<div className="bg-white sm:rounded-lg shadow w-full max-w-5xl">
						<div className="p-6 space-y-4 w-4/5 mx-auto">{children}</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

Default.propTypes = {}

const connection = connect((state) => ({}), {})(Default)
export {connection as Default}
