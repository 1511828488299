import React, { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { connect } from "react-redux"
import { sessionActions, userActions } from "../../Actions"
import { Button } from "../GeneralComponents/Elements"
import PropTypes from "prop-types"

const VerifyEmail = ({ SendVerificationEmail, VerifyEmail, Logout, GetUserById }) => {
    const { token } = useParams()

    const [userInfo, setUserInfo] = useState(undefined)
    const [isDisabled, setIsDisabled] = useState(false)
    const [countdown, setCountdown] = useState(60)
    const [requestSent, setRequestSent] = useState(false)

    const [email, setEmail] = useState("")

    useEffect(() => {
        if (token && !requestSent) {
            VerifyEmail(token, Logout)
            setRequestSent(true)
        }
        const fetchUserInfo = async () => {
            if (!userInfo) {
                const userInfo = await GetUserById()
                setUserInfo(userInfo.data[0])
            }
        }
        fetchUserInfo()
    }, [])

    useEffect(() => {
        if (userInfo) setEmail(userInfo.email)
    }, [userInfo])

    useEffect(() => {
        // Start the countdown when the button is disabled
        let timer
        if (isDisabled && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1)
            }, 1000)
        } else if (countdown === 0) {
            setIsDisabled(false)
            setCountdown(60) // Reset countdown
        }
        return () => clearInterval(timer) // Clear timer on unmount
    }, [isDisabled, countdown])

    async function sendEmail() {
        setIsDisabled(true)
        await SendVerificationEmail(email)
    }

    return (
        <Fragment>
            {token ? (
                <section className="h-full m-20 min-h-screen">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Verifying Your Email...</h1>
                                <p className="text-sm font-medium text-gray-600">Please wait. You will be automatically redirected.</p>
                            </div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className="h-full m-20 min-h-screen">
                    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Verify Your Email</h1>
                                <p className="text-sm font-medium text-gray-600">Click the link below to send a verification email to your inbox. Clicking this link will help us ensure that your account is secure and that you can access all the features we offer.</p>
                                <p className="text-sm font-medium text-gray-600">If you do not see the email in your inbox, be sure to check your spam or junk folder.</p>
                                <p className="text-sm font-medium text-red-900 italic">Emails may take a few minutes to be sent.</p>
                                <Button id="sendEmail" text={isDisabled ? "Email Sent!" : "Send Email"} disabled={isDisabled} onClick={() => sendEmail()}></Button>
                                {isDisabled ? <p className="text-sm font-bold text-gray-600">Resend email in {countdown} seconds</p> : null}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </Fragment>
    )
}

VerifyEmail.propTypes = {
    SendVerificationEmail: PropTypes.func.isRequired,
    VerifyEmail: PropTypes.func.isRequired,
    Logout: PropTypes.func.isRequired,
}

const connection = connect((state) => ({}), {
    GetUserById: userActions.GetUserById,
    SendVerificationEmail: sessionActions.SendVerificationEmail,
    VerifyEmail: sessionActions.VerifyEmail,
    Logout: sessionActions.Logout,
})(VerifyEmail)
export { connection as VerifyEmail }
