import React, {useState, useEffect} from "react"
import {connect} from "react-redux"
import PropTypes from "prop-types"

const Image = ({alt = "", initialConfig = {}, text = "", children}) => {
	const [config, setConfig] = useState({
		shape: "circle",
		size: "medium",
		stylePreset: "default",
		position: "center",
		margin: "none",
		layout: "inline",
		gap: "small",
		...initialConfig,
	})

	useEffect(() => {
		setConfig(prevConfig => ({
			...prevConfig,
			...initialConfig
		}));
	}, [initialConfig, children]);

	const gapClasses = {
		none: "gap-0",
		small: "gap-2",
		medium: "gap-8",
		large: "gap-12",
	}

	const sizeClasses = {
		small: "w-24 h-24",
		medium: "w-48 h-48",
		large: "w-64 h-64",
	}

	const stylePresets = {
		default: "bg-gray-100 shadow-md",
		minimal: "bg-white",
		vibrant: "bg-orange-100 shadow-lg",
	}

    const positionClasses = {
        center: config.layout === "inline" ? "justify-center" : "items-center",
        left: config.layout === "inline" ? "justify-start" : "items-start",
        right: config.layout === "inline" ? "justify-end" : "items-end",
        top: "items-start",
        bottom: "items-end",
        "top-left": config.layout === "inline" 
            ? "justify-start items-start" 
            : "items-start justify-start",
        "top-right": config.layout === "inline" 
            ? "justify-end items-start" 
            : "items-start justify-end",
        "bottom-left": config.layout === "inline" 
            ? "justify-start items-end" 
            : "items-end justify-start",
        "bottom-right": config.layout === "inline" 
            ? "justify-end items-end" 
            : "items-end justify-end",
    }

	const gapClass = gapClasses[config.gap] || gapClasses.medium
	const sizeClass = sizeClasses[config.size] || sizeClasses.medium
	const presetClass = stylePresets[config.stylePreset] || stylePresets.default
	const positionClass = positionClasses[config.position] || positionClasses.center

	const imageSources = children || []

	return (
		<div
			className={`
          flex 
          ${config.layout === "inline" ? "flex-row" : "flex-col"}
          ${positionClass}
          ${gapClass}
          ${config.margin === "medium" ? "m-4" : ""}
        `}
		>
			{imageSources.map(({src, href}, index) => (
				<div
					key={index}
					className={`
              ${sizeClass}
              ${presetClass}
              ${config.shape === "circle" ? "rounded-full" : "rounded-lg"}
              overflow-hidden
            `}
				>
					{href ? (
						<a
							href={href}
							className="block w-full h-full"
						>
							<img
								src={src}
								alt={`${alt} ${index + 1}`}
								className="w-full h-full object-cover"
							/>
						</a>
					) : (
						<img
							src={src}
							alt={`${alt} ${index + 1}`}
							className="w-full h-full object-cover"
						/>
					)}
				</div>
			))}
			{text && <p className="mt-2 text-gray-700 text-center">{text}</p>}
		</div>
	)
}

const connection = connect((state) => ({}), {})(Image)
export {connection as Image}
