import React, {Fragment, useEffect, useState} from "react"
import {quickActions, tierActions, userActions} from "../../Actions"
import {connect} from "react-redux"
import PropTypes from "prop-types"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import {Modal, Input, Button} from "../GeneralComponents/Elements"
import DOMPurify from "dompurify"
import Select from "react-select"
import ReactPaginate from "react-paginate"

const Emailer = ({SendMassEmail, GetUser}) => {
	const [html, setHtml] = useState("")
	const [subject, setSubject] = useState("")
	const [users, setUsers] = useState()
	const [userOptions, setUserOptions] = useState()
	const [emails, setEmails] = useState()
	const [selectedTiers, setSelectedTiers] = useState()
	const [selectedUsers, setSelectedUsers] = useState()
	const [selectedClearances, setSelectedClearances] = useState()
	const [showModal, setShowModal] = useState(false)
	const [selectLegacy, setSelectLegacy] = useState(false)
	const [selectLegacySubscribers, setSelectLegacySubscribers] = useState(false)
	const [selectLegacyNonSubscribers, setSelectLegacyNonSubscribers] = useState(false)
	const [selectAllUsers, setSelectAllUsers] = useState(false)
	const [numPerPage, setNumPerPage] = useState(20)
	const [isMarketing, setIsMarketing] = useState(0)

	const openModal = () => {
		setShowModal(true)
		// Scroll to the div surrounding the modal
		const modalContainer = document.getElementById("modal-container")
		if (modalContainer) {
			modalContainer.scrollIntoView({behavior: "smooth", block: "center"})
		}
	}
	const closeModal = () => setShowModal(false)

	useEffect(() => {
		const GetUsers = async () => {
			if (!users) {
				const {users} = await GetUser()
				if (users) setUsers(users)
			}
		}
		GetUsers()
	}, [])

	useEffect(() => {
		if (users && !userOptions) {
			const formattedUsers = []
			users.forEach((user) => {
				formattedUsers.push({
					label: user.first_name + " " + user.last_name + " (" + user.email + ")",
					value: user.email,
				})
			})
			setUserOptions(formattedUsers)
		}
	}, [users])

	const handleSubject = (value) => {
		setSubject(value)
	}
	useEffect(() => {
		let emailList = []
		const tierList = ["f-legacy", "f-silver", "f-gold", "f-platinum"]

		if (selectedUsers) {
			selectedUsers.forEach((user) => {
				if (!emailList.includes(user.email)) {
					emailList.push(user.value)
				}
			})
		}
		if (selectedTiers) {
			selectedTiers.forEach((tier) => {
				users.forEach((user) => {
					if (!emailList.includes(user.email)) {
						if (user[tier.value] == 1) emailList.push(user.email)
					}
				})
			})
		}
		if (selectedClearances) {
			selectedClearances.forEach((clearance) => {
				users.forEach((user) => {
					if (!emailList.includes(user.email)) {
						if (user.clearance == clearance.value) emailList.push(user.email)
					}
				})
			})
		}
		if (selectLegacy) {
			users.forEach((user) => {
				if (!emailList.includes(user.email)) {
					if (user.legacy == 1) emailList.push(user.email)
				}
			})
		}

		if (selectLegacy) {
			users.forEach((user) => {
				if (!emailList.includes(user.email)) {
					if (user.legacy == 1) emailList.push(user.email)
				}
			})
		}
		if (selectLegacySubscribers) {
			users.forEach((user) => {
				if (!emailList.includes(user.email)) {
					tierList.forEach((tierItem) => {
						if (user.legacy == 1 && user[tierItem] == 1) emailList.push(user.email)
					})
				}
			})
		}
		if (selectLegacyNonSubscribers) {
			users.forEach((user) => {
				if (!emailList.includes(user.email)) {
					if (user.legacy == 1 && tierList.every((tier) => user[tier] === 0)) {
						emailList.push(user.email)
					}
				}
			})
		}
		if (selectAllUsers) {
			users.forEach((user) => {
				if (!emailList.includes(user.email)) emailList.push(user.email)
			})
		}
		setEmails(emailList)
	}, [selectedTiers, selectedUsers, selectedClearances, selectLegacy, selectLegacyNonSubscribers, selectLegacySubscribers, selectAllUsers])

	const handleCheckbox = async (e, type) => {
		switch (type) {
			case "legacy":
				setSelectLegacy(e)
				break
			case "l_subs":
				setSelectLegacySubscribers(e)
				break
			case "l_n_subs":
				setSelectLegacyNonSubscribers(e)
				break
			case "all":
				setSelectAllUsers(e)
				break
			default:
		}
	}

	const removeMarketing = (emails) => {
		if (!emails) return
		let marketableEmails = []
		emails.forEach((email) => {
			let user = users.find((user) => user.email === email)
			if (user.marketing === 1) marketableEmails.push(user.email)
		})
		return marketableEmails
	}

	const submit = async () => {
		openModal()
	}

	const sendEmails = async () => {
		const sanitizedOutput = DOMPurify.sanitize(html)
		let emailList = removeMarketing(emails)
		SendMassEmail(sanitizedOutput, subject, emailList, isMarketing)
		closeModal()
	}

	const modalHtml = () => {
		return (
			<div className="pb-8 w-full">
				<div className="mb-2 text-2xl font-bold text-yellow-200 text-center">WARNINGS</div>

				<div className="w-4/5 mx-auto bg-yellow-200 p-8 rounded-lg  text-sm">
					<p className="mb-2 text-md text-md text-gray-900 font-bold">Notes: </p>

					<p className="mb-2 text-gray-900">Emails are sent slowly. This page will refresh when the emails have been sent. You can close or refresh the page while the emails are sending.</p>
					<p className="mb-2 text-gray-900">No emails will be sent to people who have unsubscribed from marketing emails.</p>
					<p className="mb-2 text-gray-900">The hello.theoryunderground email will be sent a report of who the emails were successfully sent to.</p>
					<p className="mb-2 text-gray-900">New lines here and in gmail are formatted differently as shown: </p>

					<div className="ml-4">
						<div>These two lines</div>
						<div>On the emailer here</div>
						<div className="mb-4">____</div>

						<div className="mb-3">Will look like these two lines</div>
						<div className="mb-8">In gmail</div>
					</div>

					<p className="mb-4 text-md text-gray-900 font-bold">Ensure you're following the CAN-SPAM Act and other legal requirements: </p>
					<ul className="list-disc ml-8">
						<li className=" text-gray-900">Don’t use deceptive subject lines.</li>
					</ul>
					<span className=" text-gray-900 block mt-4">The other requirements are taken care of by the unsubscribe note and email server. More may be added depending on email monitoring</span>
				</div>

				<div className="mb-2 mt-8 text-2xl font-bold text-gray-100 text-center">Example Preview</div>

				<div className="p-4 mx-auto w-full">
					<div className="border p-4 rounded shadow bg-white w-full">
						<div className="mb-10">
							<span className="font-bold">SUBJECT</span>: {subject}
						</div>

						<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html)}} />

						<p className="text-gray-500 text-size-md text-center mt-16">
							You are receiving this because you are part of the Theory Underground mailing list. If you would like to stop receiving these emails,{" "}
							<a
								href="#"
								className=" underline"
							>
								Unsubscribe
							</a>
							.<div className="mt-4">Theory Underground. 3527 S Federal Way, Ste 103 #1049, Boise, ID 83705</div>
						</p>
					</div>
				</div>

				<div className="w-4/5 mx-auto mt-16">
					<p className="text-md text-red-700 bg-red-100 p-2 rounded-lg text-center font-bold">Are you sure you want to send this email?</p>
				</div>
			</div>
		)
	}

	return (
		<Fragment>
			<div id="modal-container">
				<Modal
					isOpen={showModal}
					onClose={closeModal}
					Input={modalHtml}
					title="Email Disclaimer"
					desc=""
					buttonText="Send Email"
					buttonFunc={sendEmails}
				></Modal>
			</div>

			<section className="h-full p-20 min-h-screen">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
					<div className="bg-white rounded-lg shadow  sm:w-4/5">
						<div className="p-6 space-y-4">
							<h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center m-4 pb-10">Emailer</h1>

							<div className="mb-6">
								<div className="flex items-center space-x-4">
									<p className="text-lg font-semibold text-gray-700 w-1/6 pt-1">Email Subject</p>
									<div className="w-5/6">
										<Input
											id={`subject`}
											text={subject}
											title={``}
											placeholder={`Email Subject`}
											required={true}
											onChange={(value) => handleSubject(value)}
										/>
									</div>
								</div>
							</div>

							<div className="p-4 py-8">
								<p className="text-lg font-semibold text-gray-700 text-center">Write your email here.</p>
								<ReactQuill
									theme="snow"
									value={html}
									onChange={setHtml}
								/>
							</div>

							<div className="w-full border-t border-gray-300 pt-12 pb-4"></div>

							<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center">Users</h1>

							<div className="mb-6">
								<div className="flex items-center space-x-4">
									<p className="text-lg font-semibold text-gray-700 w-1/6">Individual Users</p>
									<Select
										isMulti
										name="Users"
										options={userOptions}
										className="basic-multi-select rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
										classNamePrefix="select"
										onChange={(e) => setSelectedUsers(e)}
									/>
								</div>

								<div className="flex items-center space-x-4 mt-4">
									<p className="text-lg font-semibold text-gray-700 w-1/6">Tiers</p>
									<Select
										isMulti
										name="tiers"
										options={[
											{label: "Legacy Bronze", value: "f-legacy"},
											{label: "Silver", value: "f-silver"},
											{label: "Gold", value: "f-gold"},
											{label: "Platinum", value: "f-platinum"},
										]}
										className="basic-multi-select rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
										classNamePrefix="select"
										onChange={(e) => setSelectedTiers(e)}
									/>
								</div>

								<div className="flex items-center space-x-4 mt-4">
									<p className="text-lg font-semibold text-gray-700 w-1/6">Clearance</p>
									<Select
										isMulti
										name="clearances"
										options={[
											{label: "Unverified", value: "unverified"},
											{label: "User", value: "user"},
											{label: "Admin", value: "admin"},
										]}
										className="basic-multi-select rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
										classNamePrefix="select"
										onChange={(e) => setSelectedClearances(e)}
									/>
								</div>
							</div>

							<div className="flex flex-wrap gap-12 py-8 justify-center">
								<div className="flex items-center space-x-2">
									<input
										type="checkbox"
										id="legacy"
										name="legacy_users"
										checked={selectLegacy}
										onChange={(e) => handleCheckbox(e.target.checked, "legacy")}
										className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500"
									/>
									<label
										htmlFor="legacy"
										className="text-lg text-gray-700"
									>
										Legacy Users
									</label>
								</div>

								<div className="flex items-center space-x-2">
									<input
										type="checkbox"
										id="legacy_subs"
										name="legacy_users"
										checked={selectLegacySubscribers}
										onChange={(e) => handleCheckbox(e.target.checked, "l_subs")}
										className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500"
									/>
									<label
										htmlFor="legacy_subs"
										className="text-lg text-gray-700"
									>
										Legacy Current Subscribers
									</label>
								</div>

								<div className="flex items-center space-x-2">
									<input
										type="checkbox"
										id="legacy_non_subs"
										name="legacy_users"
										checked={selectLegacyNonSubscribers}
										onChange={(e) => handleCheckbox(e.target.checked, "l_n_subs")}
										className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500"
									/>
									<label
										htmlFor="legacy_non_subs"
										className="text-lg text-gray-700"
									>
										Legacy Non Subscribers
									</label>
								</div>

								<div className="flex items-center space-x-2">
									<input
										type="checkbox"
										id="all_users"
										name="legacy_users"
										checked={selectAllUsers}
										onChange={(e) => handleCheckbox(e.target.checked, "all")}
										className="form-checkbox text-blue-600 rounded focus:ring-2 focus:ring-blue-500"
									/>
									<label
										htmlFor="all_users"
										className="text-lg text-gray-700"
									>
										All Users
									</label>
								</div>
							</div>

							<div className="flex justify-center">
								<p className="text-md text-gray-700">Emails: {emails ? emails.length : 0}</p>
							</div>
							<div className="flex justify-center">
								<div>
									{emails && (
										<PaginatedItems
											users={users}
											itemsPerPage={numPerPage}
											items={emails}
										></PaginatedItems>
									)}
								</div>
							</div>

							<div className="w-full border-t border-gray-300 pt-12 p-4"></div>

							<div className="flex justify-center">
								<Button
									id="submit"
									text={"Send Email"}
									onClick={() => submit(true)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

function Items({currentItems, users}) {
	const [activeTab, setActiveTab] = useState("grid")

	return (
		<div>
			{/* Tab Buttons */}
			<div className="flex gap-2 mb-4 justify-center">
				<button
					className={`px-4 py-2 rounded-md ${activeTab === "grid" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
					onClick={() => setActiveTab("grid")}
				>
					Grid View
				</button>
				<button
					className={`px-4 py-2 rounded-md ${activeTab === "table" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
					onClick={() => setActiveTab("table")}
				>
					Table View
				</button>
			</div>
			{currentItems && (
				<Fragment>
					{activeTab === "grid" && (
						<div className="flex flex-wrap gap-4">
							{currentItems.map((email) => (
								<p
									key={email}
									className="bg-blue-100 text-blue-900 p-2 rounded-md shadow-sm"
								>
									{email}
								</p>
							))}
						</div>
					)}

					{activeTab === "table" && (
						<div className="overflow-x-auto">
							<table className="w-full table-auto">
								<thead className="bg-gray-100">
									<tr>
										<th className="px-2 py-1 border">Users</th>
										<th className="px-2 py-1 border">Name</th>
										<th className="px-2 py-1 border">Customer ID</th>
										<th className="px-2 py-1 border">Marketing</th>
									</tr>
								</thead>
								<tbody>
									{users.map(
										(user) =>
											currentItems.includes(user.email) && (
												<tr
													key={user.email}
													className="hover:bg-gray-50"
												>
													<td className="px-2 py-1 border">{user.email}</td>
													<td className="px-2 py-1 border">{user.first_name + " " + user.last_name}</td>
													<td className="px-2 py-1 border">{user.customer_id}</td>
													<td className="px-2 py-1 border">{user.marketing === 1 ? "Receiving" : "Not Receiving"}</td>
												</tr>
											),
									)}
								</tbody>
							</table>
						</div>
					)}
				</Fragment>
			)}
		</div>
	)
}

function PaginatedItems({itemsPerPage, items, users}) {
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	const [itemOffset, setItemOffset] = useState(0)

	// Simulate fetching items from another resources.
	// (This could be items from props; or items loaded in a local state
	// from an API endpoint with useEffect and useState)
	const endOffset = itemOffset + itemsPerPage
	const currentItems = items.slice(itemOffset, endOffset)
	const pageCount = Math.ceil(items.length / itemsPerPage)

	// Invoke when user click to request another page.
	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % items.length
		setItemOffset(newOffset)
	}

	return (
		<>
			<Items
				currentItems={currentItems}
				users={users}
			/>
			<ReactPaginate
				breakLabel="..."
				nextLabel="next >"
				containerClassName={"pagination"}
				activeClassName={"active"}
				previousClassName={"previous"}
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				pageCount={pageCount}
				previousLabel="< previous"
				renderOnZeroPageCount={null}
			/>
		</>
	)
}

Emailer.propTypes = {}

const connection = connect((state) => ({}), {
	GetTier: tierActions.GetTier,
	GetUser: userActions.GetUser,
	SendMassEmail: quickActions.SendMassEmail,
})(Emailer)
export {connection as Emailer}
