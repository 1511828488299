import React, {Children, Fragment, useEffect, useState} from "react"
import {connect} from "react-redux"
import PropTypes from "prop-types"

const Text = ({children, initialConfig = {}}) => {
    const [config, setConfig] = useState({
        variant: "paragraph",
        size: "medium",
        weight: "normal",
        color: "default",
        align: "left",
        position: "center",
        layout: "block",
        gap: "small",
        transform: "none",
        italic: false,
        topHeight: "medium",
        bottomHeight: "medium",
		custom: "",
        ...initialConfig,
    });
	
	useEffect(() => {
        setConfig(prevConfig => ({
            ...prevConfig,
            ...initialConfig
        }));
    }, [initialConfig, children]);

	const topHClasses = {
		none: "mt-0",
		xs: "mt-2",
		small: "mt-4",
		medium: "mt-8",
		large: "mt-16",
		xl: "mt-24",
	}

	const bottomHClasses = {
		none: "mb-0",
		xs: "mb-2",
		small: "mb-4",
		medium: "mb-8",
		large: "mb-16",
		xl: "mb-24",
	}

	const sizeClasses = {
		xs: "text-xs",
		small: "text-sm",
		medium: "text-base",
		large: "text-lg",
		xl: "text-xl",
		xl2: "text-2xl",
		xl3: "text-3xl",
		xl4: "text-4xl",
	}

	const weightClasses = {
		thin: "font-thin",
		light: "font-light",
		normal: "font-normal",
		semibold: "font-semibold",
		bold: "font-bold",
	}

	const colorClasses = {
		default: "text-gray-900",
		white: "text-gray-100",
		primary: "text-blue-600",
		secondary: "text-gray-600",
		muted: "text-gray-400",
		error: "text-red-500",
		success: "text-green-500",
	}

	const variantClasses = {
		paragraph: "block",
		heading: "block font-bold",
		label: "block font-medium",
		caption: "block text-sm text-gray-500",
	}

	const gapClasses = {
		none: "gap-0",
		small: "gap-2",
		medium: "gap-8",
		large: "gap-12",
	}

	const positionClasses = {
		center: config.layout === "inline" ? "justify-center" : "items-center",
		left: config.layout === "inline" ? "justify-start" : "items-start",
		right: config.layout === "inline" ? "justify-end" : "items-end",
	}

	const transformClasses = {
		none: "",
		uppercase: "uppercase",
		lowercase: "lowercase",
		capitalize: "capitalize",
	}

	const gapClass = gapClasses[config.gap] || gapClasses.small
	const positionClass = positionClasses[config.position] || positionClasses.center

	const topHClass = topHClasses[config.topHeight] || topHClasses.medium
	const bottomHClass = bottomHClasses[config.bottomHeight] || bottomHClasses.medium

	const childrenArray = Array.isArray(children) ? children : [children]

	return (
		<div
			className={`
			flex
			${config.layout === "inline" ? "flex-row" : "flex-col"}
			${positionClass}
			${topHClass}
			${bottomHClass}
			${gapClass}
			${config.custom}
			${config.margin === "medium" ? "m-4" : ""}
		`}
		>
			{childrenArray.map((child, index) => (
				<span
					key={index}
					className={`
			  		${sizeClasses[config.size] || sizeClasses.medium}
			  		${weightClasses[config.weight] || weightClasses.normal}
			  		${colorClasses[config.color] || colorClasses.default}
			  		${variantClasses[config.variant] || variantClasses.paragraph}
			  		${transformClasses[config.transform] || transformClasses.none}
			  		${config.italic ? "italic" : ""}
			  		text-${config.align}
					`}
				>
					{child}
				</span>
			))}
		</div>
	)
}

Text.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
	initialConfig: PropTypes.shape({
		variant: PropTypes.oneOf(["paragraph", "heading", "label", "caption"]),
		size: PropTypes.oneOf(["xs", "small", "medium", "large", "xl"]),
		weight: PropTypes.oneOf(["thin", "light", "normal", "semibold", "bold"]),
		color: PropTypes.oneOf(["default", "primary", "secondary", "muted", "error", "success"]),
		align: PropTypes.oneOf(["left", "center", "right"]),
		position: PropTypes.oneOf(["center", "left", "right"]),
		layout: PropTypes.oneOf(["inline", "block"]),
		gap: PropTypes.oneOf(["none", "small", "medium", "large"]),
		transform: PropTypes.oneOf(["none", "uppercase", "lowercase", "capitalize"]),
		italic: PropTypes.bool,
		topHeight: PropTypes.string, // New prop type for top height
		bottomHeight: PropTypes.string, // New prop type for bottom height
	}),
}
const connection = connect((state) => ({}), {})(Text)
export {connection as Text}
