import React, { Fragment, useEffect, useState } from "react"
import { sessionActions } from "../../Actions"
import { connect } from "react-redux"
import { Button, Input } from "../GeneralComponents/Elements"
import ReCAPTCHA from "react-google-recaptcha"
import PropTypes from "prop-types"

const ResetPassword = ({ SendResetEmail, CheckResetCode, ResetPassword }) => {
    const [token, setToken] = useState()
    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [passwordMatch, setPasswordMatch] = useState(false)

    const [isDisabled, setIsDisabled] = useState(false)
    const [countdown, setCountdown] = useState(60)
    const [recievedCode, setRecievedCode] = useState(false)

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [requestSent, setRequestSent] = useState(false)

    useEffect(() => {
        // Start the countdown when the button is disabled
        let timer
        if (isDisabled && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1)
            }, 1000)
        } else if (countdown === 0) {
            setIsDisabled(false)
            setCountdown(60) // Reset countdown
        }
        return () => clearInterval(timer) // Clear timer on unmount
    }, [isDisabled, countdown])

    async function sendEmail() {
        let response = await SendResetEmail(email)
        if (response && response.message) setIsDisabled(true)
    }
    async function recieved() {
        setRecievedCode(true)
    }
    async function submitCode() {
        let response = await CheckResetCode(email, code)
        if (response.accessToken) {
            setToken(response.accessToken)
        }
    }
    async function submitPassword() {
        if (token) ResetPassword(token, password)
    }

    return (
        <Fragment>
            <section className="h-full min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Reset Your Password</h1>
                            {!token ? (
                                recievedCode ? (
                                    <Fragment>
                                        <p className="text-sm font-medium text-gray-600">Enter the code you recieved in your inbox.</p>
                                        <Input id={"code"} text={code} title={"Code"} placeholder={"Ex. 123456"} required={true} onChange={setCode} />
                                        <Button id="submitCode" text={"Submit"} onClick={() => submitCode()}></Button>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <p className="text-sm font-medium text-gray-600">Enter your email and click the link below to send a code to your inbox. If you do not see the email in your inbox, be sure to check your spam or junk folder.</p>
                                        <Input id={"email"} type={"text"} text={email} title={"Email"} placeholder={"john.doe@gmail.com"} required={true} disabled={isDisabled} onChange={setEmail} />
                                        <Button id="sendEmail" text={isDisabled ? "Email Sent!" : "Send Email"} disabled={isDisabled} onClick={() => sendEmail()}></Button>
                                        <div className="ml-4 inline">
                                            <Button id="recievedCode" text={"I Have The Code"} onClick={() => recieved()}></Button>
                                        </div>
                                        {isDisabled ? (
                                            <Fragment>
                                                <p className="text-sm font-bold text-gray-600">Resend code in {countdown} seconds</p>
                                            </Fragment>
                                        ) : null}
                                    </Fragment>
                                )
                            ) : (
                                <Fragment>
                                    <p className="text-sm font-medium text-gray-600">Enter your new password.</p>
                                    <Input id={"password"} type={"password"} text={password} title={"Password"} placeholder={"********"} required={true} onChange={setPassword} />
                                    <Input id={"confirm_password"} type={"password"} text={confirmPassword} title={"Confirm Password"} placeholder={"********"} required={true} onChange={setConfirmPassword} />

                                    {confirmPassword != password && confirmPassword != "" ? <p className="text-sm font-normal text-red-500">Passwords do not match.</p> : null}

                                    <p className="text-sm font-light text-gray-500">Passwords must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character.</p>

                                    <Button id="submitPassword" text={"Submit"} onClick={() => submitPassword()}></Button>
                                </Fragment>
                            )}
                            <p className="text-sm font-medium text-gray-600">Do not refresh this page. Codes may take a few minutes to send.</p>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

ResetPassword.propTypes = {
    SendResetEmail: PropTypes.func.isRequired,
    CheckResetCode: PropTypes.func.isRequired,
    ResetPassword: PropTypes.func.isRequired,
}

const connection = connect((state) => ({}), {
    SendResetEmail: sessionActions.SendResetEmail,
    CheckResetCode: sessionActions.CheckResetCode,
    ResetPassword: sessionActions.ResetPassword,
})(ResetPassword)
export { connection as ResetPassword }
