import React, {Fragment, useState, useEffect} from "react"
import {connect} from "react-redux"
import {productActions} from "../../Actions"

const Inventory = ({subContent, DownloadProduct}) => {

	const render = () => {
		return (
			<div className="m-4 w-full">
				<ul className="">
					{subContent.purchases.map((item, index) => (
						<li
							key={index}
							className="p-2 bg-white rounded border-b-2 mt-2 flex justify-between items-center"
						>
							<span className="text-gray-800 font-medium">{item.title}</span>
							<button
								onClick={() => DownloadProduct(item.uniqueId, item.img_name)}
								className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
							>
								Download
							</button>
						</li>
					))}
				</ul>
			</div>
		)
	}
	return (
		<Fragment>
			{subContent && subContent.purchases && subContent.purchases.length > 0 ? (
				<Fragment>
					<h1 className="text-3xl text-center font-bold leading-tight tracking-tight pb-4 text-gray-900 ">Your Purchases</h1>
					<div className="flex w-full">{render()}</div>
				</Fragment>
			) : (
				<p className="text-sm font-medium text-gray-800 max-w-full">
					You don't have any individual items you have purchased. Go to the
					<a
						className="text-blue-500"
						href="/shop"
					>
						{" "}
						shop page{" "}
					</a>
					to look at our catalog!
				</p>
			)}
		</Fragment>
	)
}

Inventory.propTypes = {}

const connection = connect((state) => ({}), {
	DownloadProduct: productActions.DownloadProduct,
})(Inventory)
export {connection as Inventory}
