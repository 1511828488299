import React, { Children, Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

const Card = ({ children, initialConfig = {} }) => {
    const [config, setConfig] = useState({
        variant: "default",
        padding: "medium",
        width: "auto",
        borderRadius: "medium",
        shadow: "default",
        background: "white",
        position: "center",
        layout: "inline",
        gap: "small",
        equalExpand: true,
        ...initialConfig,
    })
    const [currentChildren, setChildren] = useState(children)

    useEffect(() => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            ...initialConfig,
        }))
        setChildren(children)
    }, [initialConfig, children])

    const cardWidth = {
        size: "22",
        maxP: "220",
    }

    const gapClasses = {
        none: "gap-0",
        small: "gap-2",
        medium: "gap-8",
        large: "gap-12",
    }

    const variantClasses = {
        default: "border",
        outlined: "border-2 border-gray-300",
        elevated: "shadow-md",
        minimal: "border-0 shadow-none",
    }

    const paddingClasses = {
        none: "p-0",
        small: "p-2",
        medium: "p-4",
        large: "p-6",
    }

    const positionClasses = {
        center: config.layout === "inline" ? "justify-center" : "items-center",
        left: config.layout === "inline" ? "justify-start" : "items-start",
        right: config.layout === "inline" ? "justify-end" : "items-end",
        top: "items-start",
        bottom: "items-end",
        "top-left": config.layout === "inline" ? "justify-start items-start" : "items-start justify-start",
        "top-right": config.layout === "inline" ? "justify-end items-start" : "items-start justify-end",
        "bottom-left": config.layout === "inline" ? "justify-start items-end" : "items-end justify-start",
        "bottom-right": config.layout === "inline" ? "justify-end items-end" : "items-end justify-end",
    }

    const gapClass = gapClasses[config.gap] || gapClasses.medium
    const positionClass = positionClasses[config.position] || positionClasses.center

    // Ensure children is always an array
    const childrenArray = Array.isArray(children) ? children : [children]

    return (
        <div
            className={`
				flex
				flex-wrap
				${positionClass}
				${gapClass}
			`}
				>
            {childrenArray.map((child, index) => (
                <div
                    key={index}
                    className={`
						${variantClasses[config.variant] || variantClasses.default}
						${paddingClasses[config.padding] || paddingClasses.medium}
						rounded-lg
						flex-initial
						basis-[${cardWidth.size}%]
						min-w-[${cardWidth.maxP}px] 
						max-w-[${cardWidth.size}%] 
						bg-gray-100
					`}
                >
                    {child}
                </div>
            ))}
        </div>
    )
}

Card.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    initialConfig: PropTypes.shape({
        variant: PropTypes.oneOf(["default", "outlined", "elevated", "minimal"]),
        padding: PropTypes.oneOf(["none", "small", "medium", "large"]),
        position: PropTypes.oneOf(["center", "left", "right", "top", "bottom", "top-left", "top-right", "bottom-left", "bottom-right"]),
        layout: PropTypes.oneOf(["inline", "block"]),
        gap: PropTypes.oneOf(["none", "small", "medium", "large"]),
        equalWidth: PropTypes.bool,
    }),
}

const connection = connect((state) => ({}), {})(Card)
export { connection as Card }
