import React, {Fragment, useEffect, useState} from "react"
import {quickActions, userActions} from "../../Actions"
import {connect} from "react-redux"
import {Area, Input, Button, Modal} from "../GeneralComponents/Elements"
import Select from "react-select"
import ReactPaginate from "react-paginate"
import {ChevronDown, ChevronUp, Mail, User, CreditCard, Bell} from "lucide-react"

const Recentsubs = ({GetUser}) => {
	const [users, setUsers] = useState()
	const [userOptions, setUserOptions] = useState()
	const [selectedUsers, setSelectedUsers] = useState()

	useEffect(() => {
		const GetUsers = async () => {
			if (!users) {
				const {users} = await GetUser()

				if (users) {
					const activeUsers = users
						.filter((user) => Array.isArray(user?.subscription?.history) && user.subscription.history.some((sub) => sub.status === "active"))
						.map((user) => ({
							...user,
							subscription: {
								...user.subscription,
								history: user.subscription.history.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)),
							},
						}))
						.sort((a, b) => new Date(b.subscription.history[0]?.startDate) - new Date(a.subscription.history[0]?.startDate))

					setUsers(activeUsers)
				}
			}
		}

		GetUsers()
	}, [])

	useEffect(() => {
		if (users && !userOptions) {
			const formattedUsers = []
			users.forEach((user) => {
				formattedUsers.push({
					label: user.first_name + " " + user.last_name + " (" + user.email + ")",
					value: user.email,
				})
			})
			setUserOptions(formattedUsers)
		}
	}, [users])

	return (
		<Fragment>
			<section className="h-full p-20 min-h-screen">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
					<div className="bg-white rounded-lg shadow  sm:w-4/5">
						<div className="p-6 space-y-4">
							<h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900 text-center m-4 pb-10">Recent Subscribers</h1>
							<div>
								{users ? (
									<PaginatedItems
										users={users}
										itemsPerPage={10}
										items={users}
									/>
								) : (
									<div className="flex items-center justify-center py-6">
										<svg
											className="animate-spin h-5 w-5 text-gray-500 mr-2"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
										>
											<circle
												className="opacity-25"
												cx="12"
												cy="12"
												r="10"
												stroke="currentColor"
												strokeWidth="4"
											></circle>
											<path
												className="opacity-75"
												fill="currentColor"
												d="M4 12a8 8 0 018-8v4l3-3-3-3v4a8 8 0 108 8h-4l3 3 3-3h-4a8 8 0 01-8 8z"
											></path>
										</svg>
										<span className="text-gray-600 text-sm font-medium">Loading...</span>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}
const Items = ({currentItems}) => {
	const [openItems, setOpenItems] = useState({})

	const toggleItem = (email) => {
		setOpenItems((prev) => ({
			...prev,
			[email]: !prev[email],
		}))
	}

	const subType = (user) => {
		switch (true) {
			case user[`f-platinum`] === 1:
				return <span className="bg-gray-300 text-gray-800 px-2 py-1 rounded-lg text-sm">Platinum Membership</span>
			case user[`f-gold`] === 1:
				return <span className="bg-yellow-500 text-white px-2 py-1 rounded-lg text-sm">Gold Membership</span>
			case user[`f-silver`] === 1:
				return <span className="bg-gray-400 text-white px-2 py-1 rounded-lg text-sm">Silver Membership</span>
			case user[`f-legacy`] === 1:
				return <span className="bg-green-600 text-white px-2 py-1 rounded-lg text-sm">Legacy Membership</span>
			default:
				return <span className="bg-red-500 text-white px-2 py-1 rounded-lg text-sm">Unable to determine tier. Refer to Stripe</span>
		}
	}

	return (
		<div className="w-full space-y-4">
			{currentItems?.map((user) => (
				<div
					key={user.email}
					className="bg-white rounded-lg shadow-sm border"
				>
					<div className="p-4">
						<div className="flex items-left justify-between">
							<div className="flex items-left space-x-4">
								<div>
									<h3 className="font-medium mb-2">
										{user.first_name} {user.last_name} {subType(user)}
									</h3>
									<div className="flex items-left space-x-2 text-sm text-gray-500">
										<Mail className="h-4 w-4" />
										<span>{user.email}</span>
									</div>
									<div className="flex items-left space-x-2 mt-1">
										{Array.isArray(user?.subscription?.history) && user.subscription.history.length > 0 ? (
											<div className="text-sm p-2 rounded flex justify-left items-left">
												{console.log(user)}
												<div>
													<span
														className={`
                                                            ${user.subscription.history[0].status === "active" ? "text-green-600" : ""}
                                                            ${user.subscription.history[0].status === "canceled" ? "text-red-600" : ""}
                                                        `}
													>
														{user.subscription.history[0].status.charAt(0).toUpperCase() + user.subscription.history[0].status.slice(1)}
													</span>
												</div>
												<div className="text-gray-500">
													<span className="ml-1"> as of</span>
													<span className="mr-1"></span>
													{new Date(user.subscription.history[0].startDate).toLocaleDateString()}
												</div>
											</div>
										) : (
											<span className="text-gray-500">No Subscriptions</span>
										)}
									</div>
								</div>
							</div>
							<div className="flex items-left space-x-4">
								<div className="text-sm">
									<div className="flex items-left space-x-2">
										<CreditCard className="h-4 w-4 text-gray-500" />
										<span className="text-gray-600">{user.customer_id || "No ID"}</span>
									</div>
									<div className="flex items-left space-x-2 mt-1">
										<Bell className="h-4 w-4 text-gray-500" />
										<span className={`${user.marketing === 1 ? "text-green-600" : "text-gray-600"}`}>{user.marketing === 1 ? "Receiving Marketing" : "Not Receiving Marketing"}</span>
									</div>
								</div>

								<button
									onClick={() => toggleItem(user.email)}
									className="p-2 hover:bg-gray-50 rounded-full"
								>
									{openItems[user.email] ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
								</button>
							</div>
						</div>
					</div>

					{openItems[user.email] && (
						<div className="px-4 pb-4 border-t">
							<h4 className="text-sm font-medium my-2">Subscription History</h4>
							{user.subscription ? (
								<div className="space-y-2">
									{user.subscription.history.map((sub, idx) => (
										<div
											key={idx}
											className="text-sm p-2 rounded bg-gray-50 flex justify-between items-left"
										>
											<div>
												<span className="font-medium">Status: </span>
												<span
													className={`
                                                        ${sub.status === "active" ? "text-green-600" : ""}
                                                        ${sub.status === "canceled" ? "text-red-600" : ""}
                                                    `}
												>
													{sub.status.charAt(0).toUpperCase() + sub.status.slice(1)}
												</span>
											</div>
											<div className="text-gray-500">
												{new Date(sub.startDate).toLocaleDateString()}
												{sub.endDate ? ` - ${new Date(sub.endDate).toLocaleDateString()}` : " (Current)"}
											</div>
										</div>
									))}
								</div>
							) : (
								<div className="text-sm text-gray-500">No subscription history available</div>
							)}
						</div>
					)}
				</div>
			))}
		</div>
	)
}

function PaginatedItems({itemsPerPage, items, users}) {
	const [itemOffset, setItemOffset] = useState(0)

	const endOffset = itemOffset + itemsPerPage
	const currentItems = items.slice(itemOffset, endOffset)
	const pageCount = Math.ceil(items.length / itemsPerPage)

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % items.length
		setItemOffset(newOffset)
	}

	return (
		<>
			<Items currentItems={currentItems} />
			<ReactPaginate
				breakLabel="..."
				nextLabel="next >"
				containerClassName={"pagination"}
				activeClassName={"active"}
				previousClassName={"previous"}
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				pageCount={pageCount}
				previousLabel="< previous"
				renderOnZeroPageCount={null}
			/>
		</>
	)
}
Recentsubs.propTypes = {}

const connection = connect((state) => ({}), {GetUser: userActions.GetUser})(Recentsubs)
export {connection as Recentsubs}
