import React, {Fragment, useEffect, useState} from "react"
import {connect} from "react-redux"
import {useLocation} from "react-router-dom"
import {Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/react"
import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline"
import {sessionActions, reroute, quickActions} from "../../Actions"
import blankProfilePicture from "../../Images/Blank_Profile_Picture.png"
import logoShort from "../../Images/LogoShort.PNG"
import {useAuth} from "../../Context/AuthContext"
import PropTypes from "prop-types"
import youtube from "../../Images/youtube.jpg"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const NavigationBar = ({session, Logout, GetLive}) => {
	const [navigation, setNavigation] = useState([])
	const [sessionPages, setSessionPages] = useState([])
	const [profilePicture, setProfilePicture] = useState(null)
	const [liveId, setLiveId] = useState()

	const location = useLocation()

	useEffect(() => {
		const getLiveId = async () => {
			if (!liveId) {
				let getLive = await GetLive()

				if (getLive && getLive?.liveId) {
					setLiveId(getLive?.liveId)
				} else {
					{
						setLiveId(undefined)
					}
				}
			}
		}
		if (auth && auth.clearance) {
			getLiveId()
		}
	}, [])

	const auth = useAuth()
	useEffect(() => {
		try {
			let profilePictureURL = JSON.parse(session.userInfo).profilePicture
			if (profilePictureURL) setProfilePicture(profilePictureURL)
		} catch (err) {
			return
		}
	}, [session])

	useEffect(() => {
		let links = [
			{name: "Agora", href: "/agora", current: false},
			{name: "Courses", href: "/courses", current: false},
			{name: "Books", href: "/publications", current: false},
			{name: "Merch", href: "/merch", current: false},
		]
		let sessionLinks = [
			{name: "Login", href: "/Login", current: false},
			{name: "Register", href: "/Register", current: false},
		]
		links.forEach((link) => {
			if (link.href == "/#" + location.pathname || link.href == location.pathname) link.current = true
		})
		sessionLinks.forEach((link) => {
			if (link.href == "/#" + location.pathname || link.href == location.pathname) link.current = true
		})
		setNavigation(links)
		setSessionPages(sessionLinks)
	}, [location])

	return (
		<Fragment>
			<Disclosure
				as="nav"
				className="bg-white sticky top-0 z-50 border-b-2 border-gray-400"
			>
				<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
					<div className="relative flex h-16 items-center justify-between">
						<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
							{/* Mobile menu button*/}
							<DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
								<span className="absolute -inset-0.5" />
								<span className="sr-only">Open main menu</span>
								<Bars3Icon
									aria-hidden="true"
									className="block h-6 w-6 group-data-[open]:hidden"
								/>
								<XMarkIcon
									aria-hidden="true"
									className="hidden h-6 w-6 group-data-[open]:block"
								/>
							</DisclosureButton>
						</div>
						<div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
							<div className="flex flex-shrink-0 items-center">
								<button onClick={() => reroute("")}>
									<img
										alt="Your Company"
										src={logoShort}
										className="h-10 w-auto"
									/>
								</button>
							</div>
							<div className="hidden sm:ml-6 sm:block">
								<div className="flex space-x-4">
									{navigation.map((item) => (
										<a
											key={item.name}
											href={item.href}
											aria-current={item.current ? "page" : undefined}
											className={classNames(item.current ? "bg-gray-200 text-gray-800" : "text-gray-800 hover:bg-gray-700 hover:text-white", "rounded-md px-3 py-2 text-md font-bold")}
										>
											{item.name}
										</a>
									))}
								</div>
							</div>
						</div>
						{liveId ? (
							<a
								className="flex items-center space-x-1"
								href="/account"
							>
								<div className="pb-1">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										height="24px"
										viewBox="0 -960 960 960"
										width="24px"
										fill="#DC2626"
										className="transform translate-y-1"
									>
										<path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h480q33 0 56.5 23.5T720-720v180l160-160v440L720-420v180q0 33-23.5 56.5T640-160H160Zm0-80h480v-480H160v480Zm0 0v-480 480Z" />
									</svg>
								</div>
								<p className="text-red-600 rounded-md  py-2 text-xl font-bold">LIVE</p>
							</a>
						) : (
							<Fragment>
								{auth != "Expired" && auth != "none" ? (
									<a
										target="_blank"
										href="https://www.youtube.com/@theory_underground"
									>
										<div className="flex items-center mr-16 sm:mr-0">
											<div className="relative">
												<div className="absolute left-0 top-0 bg-transparent w-8 h-full"></div>
												<div className="pt-1 pr-2">
													<img
														alt="Youtube logo"
														src={youtube}
														className="object-cover w-8 mx-auto shadow-md rounded-lg"
													/>
												</div>
											</div>
											<p className="text-red-600 rounded-md py-2 text-xl font-bold">YouTube</p>
										</div>
									</a>
								) : (
									<></>
								)}
							</Fragment>
						)}

						{auth != "Expired" && auth != "none" ? (
							<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
								{/* Profile dropdown */}
								<Menu
									as="div"
									className="relative ml-3"
								>
									<div>
										<MenuButton className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
											<span className="absolute -inset-1.5" />
											<span className="sr-only">Open user menu</span>
											<img
												alt=""
												src={profilePicture ? profilePicture : blankProfilePicture}
												className="h-10 object-cover w-10 rounded-full border-2 border-indigo-500"
											/>
										</MenuButton>
									</div>
									<MenuItems
										transition
										className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
									>
										<MenuItem>
											<a
												href="/account"
												className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
											>
												Your Profile
											</a>
										</MenuItem>
										<MenuItem>
											<button
												className="underline block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
												onClick={() => Logout()}
											>
												Sign out
											</button>
										</MenuItem>
									</MenuItems>
								</Menu>
							</div>
						) : (
							<Fragment>
								<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
									<div className="ml-8">
										{sessionPages.map((item) => (
											<a
												key={item.name}
												href={item.href}
												aria-current={item.current ? "page" : undefined}
												className={classNames(item.current ? "bg-gray-200 text-gray-800" : "text-gray-800 hover:bg-gray-700 hover:text-white", "rounded-md px-3 py-2 text-md font-bold")}
											>
												{item.name}
											</a>
										))}
									</div>
								</div>
							</Fragment>
						)}
					</div>
				</div>

				<DisclosurePanel className="sm:hidden">
					<div className="space-y-1 px-2 pb-3 pt-2">
						{navigation.map((item) => (
							<DisclosureButton
								key={item.name}
								as="a"
								href={item.href}
								aria-current={item.current ? "page" : undefined}
								className={classNames(item.current ? "bg-gray-900 text-white" : "text-gray-400  hover:bg-gray-700 font-semibold hover:text-white", "block rounded-md px-3 py-2 text-base font-medium")}
							>
								{item.name}
							</DisclosureButton>
						))}
					</div>
				</DisclosurePanel>
			</Disclosure>
		</Fragment>
	)
}

NavigationBar.propTypes = {
	Logout: PropTypes.func.isRequired,
}

const connection = connect((state) => ({session: state.session}), {
	Logout: sessionActions.Logout,
	GetLive: quickActions.GetLive,
})(NavigationBar)
export {connection as NavigationBar}
