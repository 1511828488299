import React, {Fragment, useEffect, useState} from "react"
import {Loading, productActions, userActions} from "../../Actions"
import {connect} from "react-redux"
import PropTypes from "prop-types"
import ReactPaginate from "react-paginate"
import {Area, Input, Dropdown, Button, Modal} from "../GeneralComponents/Elements"
import Select from "react-select"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import DOMPurify from "dompurify"

const Product = ({GetProductData, CreateProduct, DeleteProduct, GetProducts, UpdateProduct}) => {
	const [nickname, setNickNname] = useState()
	const [selectedProduct, setSelectedProduct] = useState()
	const [selectedFeature, setSelectedFeature] = useState()
	const [productOptions, setProductOptions] = useState([])
	const [featureOptions, setFeatureOptions] = useState([])
	const [attachments, setAttachments] = useState()
	const [html, setHtml] = useState("")
	const [showModal, setShowModal] = useState(false)
	const [selectedAttachment, setSelectedAttachment] = useState()
	const [shopItems, setShopItems] = useState()
	const [shopLabels, setShopLabels] = useState(false)
	const [file1, setFile1] = useState(null)
	const [file2, setFile2] = useState(null)
	const [coverURL, setCoverURL] = useState("")
	const [description, setDescription] = useState("")
	const [componentTab, setComponentTab] = useState(0)
	const [fillFieldsWarn, setFillFieldsWarn] = useState(false)
	const [submitted, setSubmitted] = useState(false)

	//Edit
	const [editSelected, setEditSelected] = useState()
	const [editNickname, setEditNickname] = useState()
	const [editDescription, setEditDescription] = useState("")
	const [editSelectedProduct, setEditSelectedProduct] = useState()
	const [editSelectedFeature, setEditSelectedFeature] = useState()
	const [editCoverImage, setEditCoverImage] = useState(null)
	const [editAttachmentImage, setEditAttachmentImage] = useState(null)
	const [editCoverURL, setEditCoverURL] = useState("")

	const [activeTab, setActiveTab] = useState(0)
	const [productData, setProductData] = useState()
	const [tabs, setTabs] = useState([
		{
			header: "Coupons",
			key: "coupons",
			type: "stripe",
		},
		{
			header: "Entitlements",
			key: "entitlements",
			type: "stripe",
		},
		{
			header: "Products",
			key: "products",
			type: "stripe",
		},
		{
			header: "Prices",
			key: "prices",
			type: "stripe",
		},
	])
	const openModal = () => setShowModal(true)
	const closeModal = () => {
		setShowModal(false)
		setSelectedAttachment(null)
	}

	useEffect(() => {
		const getProductData = async () => {
			if (!productData) {
				const {pData, existing} = await GetProductData({all: true})
				if (pData) setProductData(pData)
				if (existing) setAttachments(existing)
			}
		}
		getProductData()

		const getShop = async () => {
			if (!productData) {
				const {products} = await GetProducts({all: true})
				if (products) {
					setShopItems(products)
					const shop = products.map((item) => ({label: item.title, value: item.id}))
					if (shop) setShopLabels(shop)
				}
			}
		}
		getShop()
	}, [])

	useEffect(() => {
		if (productData && !productOptions.length && !featureOptions.length) {
			const pOptions = Object.values(productData.products.data).map((product) => ({
				value: product.id,
				label: product.name || product.id,
			}))
			setProductOptions(pOptions)
			const fOptions = Object.values(productData.entitlements.data).map((feature) => ({
				value: feature.id,
				label: feature.name || feature.id,
			}))
			setFeatureOptions(fOptions)
		}
	}, [productData])

	const submit = () => {
		setSubmitted(true)

		const sanitizedOutput = DOMPurify.sanitize(html)

		const formData = new FormData()
		formData.append("title", nickname)
		formData.append("description", description)
		formData.append("product", selectedProduct.value)
		formData.append("feature", selectedFeature.value)
		formData.append("message", sanitizedOutput)
		if (file1 && file1.length > 0) {
			for (let i = 0; i < file1.length; i++) {
				formData.append("cover", file1[i])
			}
		}
		if (file2 && file2.length > 0) {
			for (let i = 0; i < file2.length; i++) {
				formData.append("attachment", file2[i])
			}
		}

		CreateProduct(formData)
	}

	const submitEdit = () => {
		if (!editSelected || !editNickname || !editDescription || !editSelectedProduct || !editSelectedFeature) {
			setFillFieldsWarn(true)
			return
		}
		setSubmitted(true)

		const sanitizedOutput = DOMPurify.sanitize(html)

		const formData = new FormData()
		formData.append("id", editSelected.id)
		formData.append("title", editNickname)
		formData.append("description", editDescription)
		formData.append("product", editSelectedProduct.value)
		formData.append("feature", editSelectedFeature.value)
		formData.append("message", sanitizedOutput)
		if (editCoverImage && editCoverImage.length > 0) {
			for (let i = 0; i < editCoverImage.length; i++) {
				formData.append("cover", editCoverImage[i])
			}
		}
		if (editAttachmentImage && editAttachmentImage.length > 0) {
			for (let i = 0; i < editAttachmentImage.length; i++) {
				formData.append("attachment", editAttachmentImage[i])
			}
		}
		UpdateProduct(formData)
		setFillFieldsWarn(false)
	}

	const deleteFile = (index) => {
		if (index === 1) {
			setFile1(null)
		} else if (index === 2) {
			setFile2(null)
		} else if (index === 3) {
			setEditCoverImage(null)
		} else if (index === 4) {
			setEditAttachmentImage(null)
		}
	}

	const setFiles = (files, index) => {
		if (index === 1) {
			const file = files[0]
			setFile1(files)
			// Make sure file is a valid File object
			if (file && file instanceof File) {
				const fileURL = URL.createObjectURL(file) // Create an object URL from the file
				setCoverURL(fileURL) // Store the object URL in the state
			} else {
				console.error("Invalid file object.")
			}
		} else if (index === 2) {
			setFile2(files)
		} else if (index === 3) {
			const file = files[0]
			setEditCoverImage(files)
			if (file && file instanceof File) {
				const fileURL = URL.createObjectURL(file)
				setEditCoverURL(fileURL)
			} else {
				console.error("Invalid file object.")
			}
		} else if (index === 4) {
			setEditAttachmentImage(files)
		}
	}

	const renderTabs = (activeTab) => {
		return (
			<Fragment>
				{productData &&
					tabs.map((tab) => {
						let tabData
						if (productData.hasOwnProperty(tab.key)) {
							tabData = productData[tab.key].data
						}

						return (
							<Fragment key={tab.key}>
								{tabData && tabs[activeTab].key == tab.key && (
									<div>
										{
											<PaginatedItems
												itemsPerPage={10}
												items={tabData}
											></PaginatedItems>
										}
									</div>
								)}
							</Fragment>
						)
					})}
			</Fragment>
		)
	}

	const readData = () => {
		return (
			<div>
				<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center">Stripe</h1>
				<div className="border-b border-gray-200">
					<ul className="flex">
						{tabs &&
							tabs.map((tab, index) => (
								<li
									key={index}
									className="mr-2"
								>
									<button
										className={`inline-block p-4 border-b-2 ${activeTab === index ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`}
										onClick={() => setActiveTab(index)}
									>
										{tab.header}
									</button>
								</li>
							))}
					</ul>
				</div>
				<div className="p-6">{renderTabs(activeTab)}</div>
			</div>
		)
	}

	const changeShopItem = (e) => {
		const id = e.value
		const selectedItem = shopItems.find((item) => item.id == id)
		setEditSelected(selectedItem)
		setEditNickname(selectedItem.title)
		setEditDescription(selectedItem.description)
		const product = productOptions.find((product) => selectedItem.product == product.value)
		setEditSelectedProduct(product)
		const feature = featureOptions.find((feature) => selectedItem.feature == feature.value)
		setEditSelectedFeature(feature)
		setEditCoverURL(selectedItem.cover)
	}

	const editAttachment = () => {
		return (
			<Fragment>
				<div className="mb-24">
					<h1 className="text-xl text-center font-bold leading-tight tracking-tight pb-4 text-gray-900 ">Edit a Shop Item</h1>

					{shopLabels ? (
						<Fragment>
							<div className="pb-4">
								<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Select an Item</h1>
								<Select
									name="Prices"
									options={shopLabels}
									className=" rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
									classNamePrefix="select"
									onChange={(e) => changeShopItem(e)}
								/>
							</div>
							<div className="pb-4">
								<Input
									id={`nickname`}
									text={editNickname}
									title={`Title`}
									placeholder={``}
									required={true}
									onChange={(value) => {
										setEditNickname(value)
									}}
								/>
							</div>
							<div className="pb-4">
								<Area
									id={`message`}
									text={editDescription}
									title={`Description`}
									placeholder={``}
									required={true}
									onChange={(value) => {
										setEditDescription(value)
									}}
								/>
							</div>
							<div className="pb-4">
								<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Select a Product</h1>
								<Select
									name="Prices"
									options={productOptions}
									className=" rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
									classNamePrefix="select"
									value={editSelectedProduct}
									onChange={(e) => setEditSelectedProduct(e)}
								/>
							</div>
							<div className="pb-4">
								<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Select a Feature</h1>
								<Select
									name="Prices"
									options={featureOptions}
									className=" rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
									classNamePrefix="select"
									value={editSelectedFeature}
									onChange={(e) => setSelectedFeature(e)}
								/>
							</div>
							<h1 className="text-md p-2 text-white bg-yellow-600 rounded-lg mt-4 w-fit mx-auto">Leave Empty to Keep Current</h1>

							<div className="pb-4 inline">
								<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900">Cover Image</h1>

								{editSelected && (
									<span className="text-sm text-gray-700 ml-4">
										Current:{" "}
										<a
											target="_blank"
											href={editSelected.cover}
											className="text-blue-500 underline"
										>
											Cover Image
										</a>
									</span>
								)}

								<div className="p-4">
									{editCoverImage ? (
										<div className="mt-2">
											<p>{editCoverImage[0].name}</p>
											<button
												type="button"
												onClick={() => deleteFile(3)}
												className="text-red-500"
											>
												Delete
											</button>
										</div>
									) : (
										<FileUpload
											fileStateSetter={setFiles}
											index={3}
											currentFile={editCoverImage}
											deleteFile={deleteFile}
										/>
									)}
								</div>
							</div>

							<div className="p-4 inline">
								<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900">Attachment</h1>

								{editSelected && (
									<span className="text-sm text-gray-700 ml-4">
										Current:{" "}
										<a
											target="_blank"
											href={editSelected.attachment}
											className="text-blue-500 underline"
										>
											Attachment
										</a>
									</span>
								)}

								<div className="p-4">
									{editAttachmentImage ? (
										<div className="mt-2">
											<p>{editAttachmentImage[0].name}</p>
											<button
												type="button"
												onClick={() => deleteFile(4)}
												className="text-red-500"
											>
												Delete
											</button>
										</div>
									) : (
										<FileUpload
											fileStateSetter={setFiles}
											index={4}
											currentFile={editAttachmentImage}
											deleteFile={deleteFile}
										/>
									)}
								</div>
							</div>

							<div className="pt-12 mx-auto text-center w-full">
								{fillFieldsWarn && <h1 className="text-md p-2 text-white bg-red-600 rounded-lg m-4 w-fit mx-auto">Ensure all non-file fields are filled out.</h1>}
								{submitted ? (
									<svg
										aria-hidden="true"
										className="w-12 h-12 text-gray-300 animate-spin fill-blue-600 mx-auto"
										viewBox="0 0 100 101"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
											fill="currentColor"
										/>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								) : (
									<Button
										id="submit"
										text={"Submit"}
										onClick={() => submitEdit()}
									></Button>
								)}
							</div>

							<div className="pb-4 pt-4 mt-8">
								<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 font-bold text-center">Preview</h1>
								<div className="flex justify-center mt-4">
									<ProductCard
										title={editNickname}
										description={editDescription}
										cover={editCoverURL}
									></ProductCard>
								</div>
							</div>
						</Fragment>
					) : (
						<svg
							aria-hidden="true"
							className="w-12 h-12 text-gray-300 animate-spin fill-blue-600 mx-auto"
							viewBox="0 0 100 101"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
								fill="currentColor"
							/>
							<path
								d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
								fill="currentFill"
							/>
						</svg>
					)}
				</div>
			</Fragment>
		)
	}

	const createAttachment = () => {
		return (
			<Fragment>
				<div className="mb-24">
					<h1 className="text-xl text-center font-bold leading-tight tracking-tight pb-4 text-gray-900 ">Create a Shop Item</h1>
					<div className="pb-4">
						<Input
							id={`nickname`}
							text={nickname}
							title={`Title`}
							placeholder={``}
							required={true}
							onChange={(value) => {
								setNickNname(value)
							}}
						/>
					</div>
					<div className="pb-4">
						<Area
							id={`message`}
							text={description}
							title={`Description`}
							placeholder={``}
							required={true}
							onChange={(value) => {
								setDescription(value)
							}}
						/>
					</div>

					<div className="pb-4">
						<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Select a Product</h1>
						<Select
							name="Prices"
							options={productOptions}
							className=" rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
							classNamePrefix="select"
							onChange={(e) => setSelectedProduct(e)}
						/>
					</div>
					<div className="pb-4">
						<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Select a Feature</h1>
						<Select
							name="Prices"
							options={featureOptions}
							className=" rounded-md  focus:ring-2 focus:ring-blue-500 focus:border-blue-500 p-2 flex-1"
							classNamePrefix="select"
							onChange={(e) => setSelectedFeature(e)}
						/>
					</div>
					<div className="pb-4">
						<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Cover Image</h1>
						<div className="p-4">
							{file1 ? (
								<div className="mt-2">
									<p>{file1[0].name}</p>
									<button
										type="button"
										onClick={() => deleteFile(1)}
										className="text-red-500"
									>
										Delete
									</button>
								</div>
							) : (
								<FileUpload
									fileStateSetter={setFiles}
									index={1}
									currentFile={file1}
									deleteFile={deleteFile}
								/>
							)}
						</div>
					</div>

					<div className="p-4">
						<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 ">Attachment</h1>
						{file2 ? (
							<div className="mt-2">
								<p>{file2[0].name}</p>
								<button
									type="button"
									onClick={() => deleteFile(2)}
									className="text-red-500"
								>
									Delete
								</button>
							</div>
						) : (
							<FileUpload
								fileStateSetter={setFiles}
								index={2}
								currentFile={file2}
								deleteFile={deleteFile}
							/>
						)}
					</div>

					<div className="pt-12 mx-auto text-center w-full">
						{submitted ? (
							<svg
								aria-hidden="true"
								className="w-12 h-12 text-gray-300 animate-spin fill-blue-600 mx-auto"
								viewBox="0 0 100 101"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
									fill="currentColor"
								/>
								<path
									d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
									fill="currentFill"
								/>
							</svg>
						) : (
							<Button
								id="submit"
								text={"Submit"}
								onClick={() => submit()}
							></Button>
						)}
					</div>

					<div className="pb-4 pt-4 mt-8">
						<h1 className="text-md pl-2 tracking-tight pt-2 text-gray-900 font-bold text-center">Preview</h1>
						<div className="flex justify-center mt-4">
							<ProductCard
								title={nickname}
								description={description}
								cover={coverURL}
							></ProductCard>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}

	const deleteAttachmentConfirm = (id) => {
		attachments.forEach((attachment, index) => {
			if (attachment.id === id) {
				setSelectedAttachment(attachments[index])
			}
		})
		setShowModal(true)
	}

	const deleteWarningHTML = () => {
		return (
			<Fragment>
				<div className="w-full m-auto p-4">
					<p className="mb-8 text-md text-gray-200 text-center">Are you sure you want to delete this item?</p>
					<p className="mb-8 text-md text-gray-200 text-center p-2">Deleting will disable the item from showing up. Anyone who has purchased the item will still have access to it.</p>

					{selectedAttachment && (
						<ul className="space-y-4 text-sm text-gray-300 mb-4 p-12">
							<li>
								<strong>ID:</strong> {selectedAttachment.id}
							</li>
							<li>
								<strong>Title:</strong> {selectedAttachment.title}
							</li>
							<li>
								<strong>Description:</strong> {selectedAttachment.description}
							</li>
							<li>
								<strong>Price:</strong> {selectedAttachment.price}
							</li>
							<li>
								<strong>Product:</strong> {selectedAttachment.product}
							</li>
							<li>
								<strong>Feature:</strong> {selectedAttachment.feature}
							</li>
							<li>
								<strong>Cover:</strong>{" "}
								<a
									href={selectedAttachment.cover}
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-500"
								>
									Cover
								</a>
							</li>
							<li>
								<strong>Attachment:</strong> {}
								<a
									href={selectedAttachment.attachment}
									target="_blank"
									rel="noopener noreferrer"
									className="text-blue-500"
								>
									Attachment
								</a>
							</li>
						</ul>
					)}
				</div>
			</Fragment>
		)
	}

	const deleteAttachment = () => {
		DeleteProduct(selectedAttachment)
		closeModal()
	}

	const manageProducts = () => {
		let enhancedAttachments
		if (attachments) {
			enhancedAttachments = attachments.map((attachment) => ({
				Delete: () => deleteAttachmentConfirm(attachment.id),
				...attachment,
			}))
		}

		return (
			<div>
				<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 text-center pb-4">Manage Shop Items</h1>

				<div className="border-b border-gray-200">
					{enhancedAttachments && attachments && (
						<Fragment>
							<div>
								<PaginatedItems
									itemsPerPage={10}
									items={enhancedAttachments}
								/>
							</div>
							<div>
								{enhancedAttachments.cover && (
									<a
										href={enhancedAttachments.cover}
										target="_blank"
										rel="noopener noreferrer"
									>
										{enhancedAttachments.cover}
									</a>
								)}
							</div>
						</Fragment>
					)}
				</div>
			</div>
		)
	}

	const renderComponent = () => {
		switch (componentTab) {
			case 0:
				return manageProducts()
			case 1:
				return createAttachment()
			case 2:
				return editAttachment()
			case 3:
				return readData()
			default:
				break
		}
	}

	return (
		<Fragment>
			<section className="h-full p-20 min-h-screen">
				<Modal
					isOpen={showModal}
					onClose={closeModal}
					title="Delete Item"
					desc=""
					Input={deleteWarningHTML}
					buttonText="Delete"
					buttonFunc={deleteAttachment}
				></Modal>

				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
					<div className="bg-white rounded-lg shadow w-full">
						<div className="p-6 space-y-4">
							<div>
								<h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 text-center mb-4">Product Management</h1>
								<div className="border-b border-gray-200 mb-8">
									<ul className="flex">
										<li className="mr-2">
											<button
												className={`inline-block p-4 border-b-2 ${componentTab === 0 ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`}
												onClick={() => setComponentTab(0)}
											>
												Manage Shop Items
											</button>
											<button
												className={`inline-block p-4 border-b-2 ${componentTab === 1 ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`}
												onClick={() => setComponentTab(1)}
											>
												Create Shop Item
											</button>
											<button
												className={`inline-block p-4 border-b-2 ${componentTab === 2 ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`}
												onClick={() => setComponentTab(2)}
											>
												Edit Shop Item
											</button>
											<button
												className={`inline-block p-4 border-b-2 ${componentTab === 3 ? "border-blue-500 text-blue-500" : "border-transparent text-gray-500"}`}
												onClick={() => setComponentTab(3)}
											>
												Stripe Information
											</button>
										</li>
									</ul>
								</div>
							</div>
							{renderComponent()}
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

function Items({currentItems}) {
	return (
		<div className="overflow-x-auto">
			{currentItems && currentItems.length > 0 && (
				<Fragment>
					<table className="min-w-full table-auto border-collapse">
						<thead className="bg-gray-200">
							<tr className="text-left text-sm font-semibold text-gray-700">
								{Object.keys(currentItems[0]).map((key) => (
									<th
										key={key}
										className="px-4 py-2 border-b whitespace-nowrap"
									>
										{key}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{currentItems.map((item, rowIndex) => (
								<tr
									key={rowIndex}
									className={`hover:bg-gray-100 ${rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}`}
								>
									{Object.entries(item).map(([key, value], colIndex) => (
										<td
											key={colIndex}
											className="px-4 py-2 border-b text-sm text-gray-800 whitespace-normal"
										>
											{typeof value === "boolean" ? (
												value ? (
													"True"
												) : (
													"False"
												)
											) : typeof value === "string" && value.startsWith("https") ? (
												<a
													href={value}
													target="_blank"
													rel="noopener noreferrer"
													className="text-blue-500 hover:underline"
												>
													{value.slice(0, 15)}...
												</a>
											) : typeof value === "function" ? (
												<button
													onClick={value}
													className="px-2 py-1 bg-blue-500 text-white text-xs font-medium rounded hover:bg-blue-600"
												>
													{key}
												</button>
											) : Array.isArray(value) || typeof value === "object" ? (
												JSON.stringify(value)
											) : (
												JSON.stringify(value).slice(0, 30) + "..."
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</Fragment>
			)}
		</div>
	)
}

function PaginatedItems({itemsPerPage, items}) {
	const [itemOffset, setItemOffset] = useState(0)

	const endOffset = itemOffset + itemsPerPage
	const currentItems = items.slice(itemOffset, endOffset)
	const pageCount = Math.ceil(items.length / itemsPerPage)

	const handlePageClick = (event) => {
		const newOffset = (event.selected * itemsPerPage) % items.length
		setItemOffset(newOffset)
	}

	return (
		<>
			<Items currentItems={currentItems} />
			<ReactPaginate
				breakLabel="..."
				nextLabel="next >"
				containerClassName={"pagination"}
				activeClassName={"active"}
				previousClassName={"previous"}
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				pageCount={pageCount}
				previousLabel="< previous"
				renderOnZeroPageCount={null}
			/>
		</>
	)
}

const ProductCard = ({title, description, cover}) => {
	return (
		<div className="w-full sm:w-2/5 md:w-1/3 lg:w-1/4 border border-gray-200 rounded-lg shadow-lg overflow-hidden flex flex-col">
			<img
				src={cover}
				alt={title}
				className="w-full h-48 object-cover"
			/>
			<div className="p-4 flex flex-col flex-grow">
				<h2 className="text-lg font-semibold mb-2">{title}</h2>
				<p className="text-gray-500 text-sm mb-4">{description}</p>
				<div className="mt-auto">
					<button className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">Add To Cart</button>
				</div>
			</div>
		</div>
	)
}

const FileUpload = ({fileStateSetter, index}) => {
	const [files, setFiles] = useState(null)

	const handleDrop = (e) => {
		e.preventDefault()
		if (e.dataTransfer.files.length > 0) {
			setFiles(e.dataTransfer.files)
		}
	}

	const handleDragOver = (ev) => {
		ev.preventDefault()
	}

	const handleFileChange = (e) => {
		if (e.target.files.length > 0) {
			setFiles(e.target.files)
		}
	}

	useEffect(() => {
		if (files) {
			submitFile() // Trigger submit after the files state is updated
		}
	}, [files]) // Only run submitFile when 'files' changes

	const submitFile = () => {
		if (files && fileStateSetter) {
			fileStateSetter(files, index) // Send the files to the parent via the setter function
		}
	}

	return (
		<form className="flex items-center justify-center">
			<div
				className="flex flex-col items-center justify-center w-full h-4/5 border-2 border-dashed rounded-lg cursor-pointer hover:bg-gray-800 bg-gray-200 hover:bg-gray-100 border-gray-600 hover:border-gray-500 hover:bg-gray-600"
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				onClick={() => document.getElementById(`dropzone-file-${index}`).click()} // Trigger input click
			>
				<div className="flex flex-col items-center justify-center pt-5 pb-6">
					<svg
						className="w-8 h-8 mb-4 text-gray-400"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 20 16"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
						/>
					</svg>
					<p className="mb-2 text-sm text-gray-400">
						<span className="font-semibold">Click to upload</span> or drag and drop
					</p>
					<p className="text-xs text-gray-400">Any files (Max Size: 5MB)</p>
				</div>
			</div>
			<input
				id={`dropzone-file-${index}`}
				type="file"
				className="hidden"
				onChange={handleFileChange}
			/>
		</form>
	)
}

Product.propTypes = {}

const connection = connect((state) => ({}), {
	GetProductData: productActions.GetProductData,
	CreateProduct: productActions.CreateProduct,
	DeleteProduct: productActions.DeleteProduct,
	GetProducts: productActions.GetProducts,
	UpdateProduct: productActions.UpdateProduct,
})(Product)
export {connection as Product}
