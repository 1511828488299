import React, {Fragment, useEffect, useState} from "react"
import {reroute, userActions} from "../../Actions"
import {connect} from "react-redux"
import background from "../../Images/herobackground.png"
import logoFull from "../../Images/LogoFull.jpg"
import PropTypes from "prop-types"

import person1 from "../../Images/People/person1.png"
import person2 from "../../Images/People/person2.png"
import person3 from "../../Images/People/person3.png"
import person4 from "../../Images/People/person4.png"
import person5 from "../../Images/People/person5.png"

import map from "../../Images/Events/EventMap.png"

const Home = () => {
	return (
		<Fragment>
			<section className="h-full py-12 pb-20 relative w-full">
				<div className="w-full">
					<h2 className="mb-12 text-5xl text-center tracking-tight font-extrabold text-white drop-shadow-lg">Theory Underground</h2>

					<div>
						<div className="flex mx-auto justify-center items-center">
							<Embed
								url={"https://www.youtube.com/watch?v=KpVReyaK-Do"}
							/>
						</div>

						<div className="relative flex items-center justify-center w-4/5 mx-auto mb-20">
							<div className="absolute inset-0 rounded-full"></div>

							<div className="absolute">
								<div className="spin-animation"></div>
								<button
									onClick={() => {
										reroute("subscribe")
									}}
									className="relative text-center text-xl font-bold text-white bg-gradient-to-r from-[#FA0FBA] to-[#0DD6DF] px-4 py-2 rounded-lg z-10"
								>
									Get Involved
								</button>
							</div>

							<img
								alt={"Event Map"}
								src={map}
								className="object-cover w-auto m-16 mx-auto shadow-md rounded-lg"
							/>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

const Embed = ({url, width = "1100", height = "600/9", autoplay = false}) => {
	// Extract video ID from the URL
	const videoId = url.split("v=")[1]?.split("&")[0]

	if (!videoId) {
		return <div>Invalid YouTube URL</div>
	}

	return (
        <div className="video-responsive">
			<iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
			/>
		</div>
	)
}

Home.propTypes = {}

const connection = connect((state) => ({}), {})(Home)
export {connection as Home}
