import React, {Fragment, useEffect, useState} from "react"
import {reroute, userActions} from "../../Actions"
import {connect} from "react-redux"

const PurchaseSuccess = () => {
	return (
		<Fragment>
			<section className="h-full mt-20 min-h-screen">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
					<h2 className="my-12 text-5xl text-center tracking-tight font-extrabold text-gray-100 drop-shadow-xl ">Success</h2>

					<div className="w-full sm:w-3/5 bg-white rounded-lg shadow md:mt-0 xl:p-0">
						<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
							<p className="text-md font-medium text-gray-600 m-4 md:m-12 text-center">
								Thank you for your purchase! You can download your files on{" "}
								<a
									href="/account"
									className="text-blue-600 underline"
								>
									your account page
								</a>{" "}
								under purchases.
							</p>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

PurchaseSuccess.propTypes = {}

const connection = connect((state) => ({}), {})(PurchaseSuccess)
export {connection as PurchaseSuccess}
