import React, {Fragment, useEffect, useState} from "react"
import {playlistActions} from "../../Actions"
import {connect} from "react-redux"
import {PlaylistGroup, Video, Items, PaginatedItems} from "../Subscriber/Playlists"
import PropTypes from "prop-types"
import poster from '../../Images/AgoraPoster.png';

import e1 from '../../Images/Events/event1.png'
import e2 from '../../Images/Events/event2.png'
import e3 from '../../Images/Events/event3.png'
import e4 from '../../Images/Events/event4.png'
import e5 from '../../Images/Events/event5.png'
import e6 from '../../Images/Events/event6.png'
import e7 from '../../Images/Events/event7.png'
import e8 from '../../Images/Events/event8.png'
import e9 from '../../Images/Events/event9.png'
import e10 from '../../Images/Events/event10.png'
import e11 from '../../Images/Events/event11.png'

const Agora = ({GetPlaylist, GetPlaylistById}) => {
	const [playlist, setPlaylist] = useState()
	const [data, setData] = useState()

	const pid = "PLlcbaQ1cp2TLHTqxOKX7lktzQc4UUNJSf"

	const courses = [
		{src: e1, alt: "Event1", link: ""},
		{src: e2, alt: "Event2", link: ""},
		{src: e3, alt: "Event3", link: ""},
		{src: e4, alt: "Event4", link: ""},
		{src: e5, alt: "Event5", link: ""},
		{src: e6, alt: "Event6", link: ""},
		{src: e7, alt: "Event7", link: ""},
		{src: e8, alt: "Event8", link: ""},
		{src: e9, alt: "Event9", link: ""},
		{src: e10, alt: "Event10", link: ""},
		{src: e11, alt: "Event11", link: ""},
	]

	useEffect(() => {
		const fetchPlaylistVideos = async () => {
			if (!playlist) {
				const playlistData = await GetPlaylistById({id: pid})
				setPlaylist(playlistData)
			}
		}
		fetchPlaylistVideos()
	}, [])

	useEffect(() => {
		if (!data && playlist) {
			setData({
				title: "Opening the AGORA",
				videos: playlist.items.map((item) => ({
					id: item.contentDetails.videoId,
					thumbnail: item.snippet.thumbnails.medium.url,
					title: item.snippet.title,
				})),
				playlist: pid,
				token: playlist.nextPageToken,
				pageInfo: playlist.pageInfo || 6,
				perPage: 6,
			})
		}
	}, [playlist])

	const renderVideos = () => {
		const fetchVideos = async () => {
			try {
				const nextPageVideos = await GetPlaylistById({id: data.playlist, token: data.token})
				setData((prevData) => ({
					...prevData,
					videos: [
						...prevData.videos,
						...nextPageVideos.items.map((item) => ({
							id: item.contentDetails.videoId,
							thumbnail: item.snippet.thumbnails.medium.url,
							title: item.snippet.title,
						})),
					],
					token: nextPageVideos.nextPageToken,
				}))
			} catch (error) {
				console.error("Error fetching videos:", error)
			}
		}

		return (
			data && (
				<div className="mb-8 bg-white rounded-lg p-4 shadow-lg border border-gray-200 w-full mx-auto">
                    <h2 className="text-center text-4xl font-bold text-gray-800 mb-6 border-b pb-2">Opening the AGORA</h2>
					<PaginatedItems
						itemsPerPage={data.perPage}
						items={data.videos}
						fetchVideos={fetchVideos}
						pageInfo={data.pageInfo}
					/>
				</div>
			)
		)
	}

	const renderEvents = () => {
		/*
		<div className="md:grid md:grid-cols-2 gap-8">
			{courses.map(({ src, alt }, index) => (
				<div key={index} className="relative w-auto h-72 overflow-hidden rounded-lg shadow-md mx-auto">
					<div className="absolute inset-0 border-4 border-white blur-sm"></div>
					<img 
						alt={alt} 
						src={src} 
						className="object-cover w-72 h-full shadow-md"
					/>
				</div>
			))}
		</div>
		*/
		return (
			<div className="mb-8 bg-white rounded-lg p-4 shadow-lg border border-gray-200 w-full mx-auto">
				<h2 className="text-center text-4xl font-bold text-gray-800 mb-6 border-b pb-2">Previous Events</h2>
				<div  className="flex flex-wrap gap-8 justify-center">
					{courses.map(({ src, alt }, index) => (
						<img 
							key={index}
							src={src} 
							alt={alt} 
							className="size-64 object-cover rounded-md shadow-lg"
						/>
					))}
				</div>
			</div>
		)
	}

	return (
		<Fragment>
            <section className="h-full min-h-screen">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto mt-8 mb-32">
					<div className="w-full sm:w-5/6 max-w-4xl">{renderVideos()}</div>

                    <div className=" sm:w-5/6 max-w-4xl rounded-lg bg-white p-8 h-auto ">
                        <img
                            src={poster}
                            alt="Image 1"
                            className="object-cover mx-auto w-11/12 h-auto"
                        />
                    </div>

					<div className="w-full sm:w-5/6 max-w-4xl mt-12">{renderEvents()}</div>

				</div>
			</section>
		</Fragment>
	)
}

Agora.propTypes = {}

const connection = connect((state) => ({}), {
	GetPlaylist: playlistActions.GetPlaylist,
	GetPlaylistById: playlistActions.GetPlaylistById,
})(Agora)

export {connection as Agora}
