import React, {Fragment, useEffect, useState} from "react"
import {productActions, reroute, subContentActions, userActions} from "../../Actions"
import {connect} from "react-redux"
import PropTypes from "prop-types"
import {Area, Input, Dropdown, Button} from "../GeneralComponents/Elements"
import {useAuth} from "../../Context/AuthContext"
import {Loading} from "../GeneralComponents/Elements/Loading"

const Shop = ({GetProducts, ProductCheckout, GetSubContent, GetUserById, PublishProduct}) => {
	const [productData, setProductData] = useState()
	const [cart, setCart] = useState([])
	const [subContent, setSubContent] = useState()
	const [userInfo, setUserInfo] = useState(undefined)
	const [noItems, setNoItems] = useState(false)

	const auth = useAuth()

	useEffect(() => {
		if (auth && auth.clearance) {
			const fetchUserInfo = async () => {
				if (!userInfo) {
					const userInfo = await GetUserById()
					if (userInfo?.data && userInfo?.data.length > 0) {
						setUserInfo(userInfo?.data[0])
					} else {
						setUserInfo(undefined)
					}
				}
			}
			fetchUserInfo()

			const fetchSubContent = async () => {
				if (!subContent) {
					const subContent = await GetSubContent()
					setUserInfo(subContent)
					let idArr
					try {
						if (subContent && subContent.purchases) {
							idArr = subContent.purchases.map((item) => item.uniqueId)
						} else {
							idArr = []
						}
					} catch (err) {
						console.log(err.message)
						idArr = []
					}
					setSubContent(idArr)
				}
			}
			fetchSubContent()

			const getProductData = async () => {
				if (!productData) {
					const {products} = await GetProducts()
					if (products) setProductData(products)
				}
			}
			getProductData()
		}
	}, [])

	useEffect(() => {
		if (productData && subContent) {
			const products = productData.map((product) => product.id)
			let hasPurchases = !products.length || products.every((product) => subContent.includes(product))
			setNoItems(hasPurchases)
		}
	}, [productData, subContent])

	const Checkout = () => {
		ProductCheckout(cart)
	}
	const handleCartUpdate = (product, inCart) => {
		if (inCart) {
			setCart((prevCart) => prevCart.filter((item) => item != product.price))
		} else {
			setCart((prevCart) => [...prevCart, product.price])
		}
	}

	const renderContent = (product) => {
		if (auth && auth.clearance === "admin") {
			return (
				<Fragment key={product.id}>
					<ProductCard
						key={product.id}
						product={product}
						cart={cart}
						cartCallback={handleCartUpdate}
						PublishProduct={PublishProduct}
						initialStatus={product.active}
						clearance={auth.clearance}
					/>{" "}
				</Fragment>
			)
		} else {
			return (
				<Fragment key={product.id}>
					{!subContent.includes(product.id) && (
						<ProductCard
							key={product.id}
							product={product}
							cart={cart}
							cartCallback={handleCartUpdate}
							PublishProduct={PublishProduct}
							initialStatus={product.active}
							clearance={auth.clearance}
						/>
					)}{" "}
				</Fragment>
			)
		}
	}
	const shopContent = () => {
		return (
			<Fragment>
				<section className="h-full min-h-screen w-full">
					<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto w-full mb-12">
						<h2 className="my-12 text-5xl text-center tracking-tight font-extrabold text-gray-100 drop-shadow-xl ">SHOP</h2>
						<div className="bg-white rounded-lg shadow sm:w-4/5">
							<div className="p-6 space-y-4 text-center">
								<div className="flex flex-wrap gap-8 justify-center">
									{!noItems ? (
										productData ? (
											subContent && productData.length > 0 && productData.map((product) => renderContent(product))
										) : (
											<svg
												aria-hidden="true"
												className="w-12 h-12 text-gray-300 animate-spin fill-blue-600 mx-auto"
												viewBox="0 0 100 101"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
													fill="currentColor"
												/>
												<path
													d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
													fill="currentFill"
												/>
											</svg>
										)
									) : (
										<p className="text-md font-medium text-gray-600 m-4 md:m-12">
											There are currently no items available for sale, or you've purchased all of the available items. Check{" "}
											<a
												href="/account"
												className="text-blue-600 underline"
											>
												your account page
											</a>{" "}
											to see your purchases or check back later!
										</p>
									)}
								</div>

								{!noItems && (
									<div>
										<button
											id={"checkout"}
											className={`w-fit inline text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xl px-4 py-2.5 text-center bg-green-600 m-8`}
											onClick={() => Checkout()}
										>
											Checkout
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</section>
			</Fragment>
		)
	}

	if (auth && auth.clearance) {
		return shopContent()
	} else if (auth === undefined) {
		return <Loading></Loading>
	} else {
		return (
			<section className="h-full mt-20 min-h-screen">
				<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
					<div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
						<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
							<h1 className="text-2xl font-semibold text-gray-800 text-center">You must be logged in to view the shop.</h1>
							<div className="flex w-full justify-center gap-4">
								<Button
									id="login"
									text={"Login"}
									onClick={() => reroute("login")}
								></Button>
								<Button
									id="register"
									text={"Register"}
									onClick={() => reroute("register")}
								></Button>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

const ProductCard = ({product, cart, cartCallback, PublishProduct, initialStatus, clearance}) => {
	const {id, title, description, price, cover} = product
	const isInCart = cart?.some((item) => item === price)

	const handleAddToCart = () => {
		cartCallback(product, isInCart)
	}

	const [isPublished, setisPublished] = useState(initialStatus)
	const toggleOpen = (isPublished) => {
		setisPublished(isPublished == 1 ? 0 : 1)
		PublishProduct(product.id, isPublished)
	}
	return (
		<div className="relative w-full sm:w-2/5 max-w-sm border border-gray-200 rounded-lg shadow-lg overflow-hidden flex flex-col">
			<img
				src={cover}
				alt={title}
				className="w-full h-48 object-cover"
			/>

			{clearance === "admin" && (
				<button
					onClick={() => toggleOpen(isPublished)}
					className={`absolute top-2 right-2 ${isPublished ? "bg-green-400 hover:bg-green-200" : "bg-red-400 hover:bg-red-200"} rounded-full p-2 shadow  focus:outline-none`}
				>
					{isPublished ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6 text-gray-700"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
							/>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-1.274 4.057-5.065 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
							/>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6 text-gray-700"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M13.875 18.825A10.05 10.05 0 0112 19c-4.477 0-8.268-2.943-9.542-7a10.05 10.05 0 012.168-3.255M8.88 8.88a3 3 0 104.24 4.24M17.657 17.657L6.343 6.343"
							/>
						</svg>
					)}
				</button>
			)}

			<div className="p-4 flex flex-col flex-grow">
				<h2 className="text-lg font-semibold mb-2">{title}</h2>
				<p className="text-gray-500 text-sm mb-4">{description}</p>
				<div className="mt-auto">
					<button
						onClick={handleAddToCart}
						className={`w-full py-2 px-4 rounded text-white ${isInCart ? "bg-gray-400 hover:bg-gray-500" : "bg-blue-500 hover:bg-blue-600"}`}
					>
						{isInCart ? "In Cart" : "Add To Cart"}
					</button>
				</div>
			</div>
		</div>
	)
}

Shop.propTypes = {}

const connection = connect((state) => ({}), {
	GetProducts: productActions.GetProducts,
	ProductCheckout: productActions.ProductCheckout,
	GetSubContent: subContentActions.GetSubContent,
	GetUserById: userActions.GetUserById,
	PublishProduct: productActions.PublishProduct,
})(Shop)
export {connection as Shop}
