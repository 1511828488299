import React, { Fragment } from "react";
import { userActions } from "../../Actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { 
    Edit, 
    Layers, 
    Play, 
    Video, 
    Mail, 
    Package, 
    ShoppingCart, 
    UserCheck 
} from "lucide-react"; // Add your icon imports here

const Admin = () => {
    const links = [
        { href: "/editobject", label: "Stripe", icon: <Edit className="h-6 w-6 text-blue-600" /> },
        { href: "/tiers", label: "Tiers", icon: <Layers className="h-6 w-6 text-blue-600" /> },
        { href: "/playlists", label: "Playlists", icon: <Play className="h-6 w-6 text-blue-600" /> },
        { href: "/golive", label: "GoLive", icon: <Video className="h-6 w-6 text-blue-600" /> },
        { href: "/emailer", label: "Emailer", icon: <Mail className="h-6 w-6 text-blue-600" /> },
        { href: "/product", label: "Product", icon: <Package className="h-6 w-6 text-blue-600" /> },
        { href: "/shop", label: "Shop", icon: <ShoppingCart className="h-6 w-6 text-blue-600" /> },
        { href: "/subs", label: "Recent Subscribers", icon: <UserCheck className="h-6 w-6 text-blue-600" /> },
    ];

    return (
        <Fragment>
            <section className="h-full p-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="bg-white rounded-lg shadow-md sm:w-4/5">
                        <div className="p-6 space-y-4 text-center">
                            <h1 className="text-2xl font-bold leading-tight tracking-tight text-gray-900 ">Admin Links</h1>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                                {links.map((link, index) => (
                                    <div key={index} className="bg-blue-50 p-4 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                                        <a href={link.href} className="flex items-center space-x-3 text-blue-700 hover:text-blue-900">
                                            {link.icon}
                                            <span className="text-sm font-medium">{link.label}</span>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

Admin.propTypes = {};

const connection = connect((state) => ({}), {})(Admin);
export { connection as Admin };
