import React, { Children, Fragment, useEffect, useState } from "react"
import { userActions } from "../../Actions"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Default } from "../GeneralComponents/Layouts"
import { Card, Image, Text } from "../GeneralComponents/Blocks"
import construction from "../../Images/construction.jpg"

import AutisticSubject from "../../Images/Courses/autisticsubject.png"
import BeingAndTime from "../../Images/Courses/BeingAndTime.png"
import Doxology from "../../Images/Courses/doxology.png"
import Illich from "../../Images/Courses/illich.png"
import JacqueLacan from "../../Images/Courses/JacqueLacan.png"
import Marxism from "../../Images/Courses/marxism.png"
import Nickland from "../../Images/Courses/nickland.png"
import PMC from "../../Images/Courses/pmc.png"
import Zizek from "../../Images/Courses/zizek.png"

import BenjaminStudebaker from "../../Images/Courses/New/BenjaminStudebaker.png"
import DavidMcKerracher from "../../Images/Courses/New/DavidMcKerracher.png"
import MichaelDowns from "../../Images/Courses/New/MichaelDowns.png"
import NinaPower from "../../Images/Courses/New/NinaPower.png"
import TUBASEBRAID from "../../Images/Courses/New/TUBASEBRAID.png"

const Courses = ({}) => {
    const [textConfig, setTextConfig] = useState({
        variant: "paragraph",
        size: "medium",
        weight: "bold",
        color: "default",
        align: "center",
        position: "center",
        layout: "block",
        gap: "small",
        transform: "none",
        italic: false,
        topHeight: "medium",
        bottomHeight: "xs",
    })
    const [imageConfig, setImageConfig] = useState({
        shape: "square",
        size: "medium",
        stylePreset: "default",
        position: "center",
        margin: "none",
        layout: "inline",
        gap: "small",
    })
    const [cardConfig, setCardConfig] = useState({
        variant: "default",
        padding: "medium",
        width: "auto",
        borderRadius: "medium",
        shadow: "outlined",
        background: "white",
        position: "center",
        gap: "medium",
        equalWidth: true,
    })

    const [courseData, setCourseData] = useState([
        { price: "price123", inCart: false, level: 2, image: AutisticSubject, text: "The Autistic Subject", title: "Explores the intersections of autism and subjectivity, offering unique perspectives on identity and cognition." },
        { price: "price124", inCart: false, level: 2, image: BeingAndTime, text: "Being and Time", title: "A seminal work in philosophy examining existence, time, and the nature of being." },
        { price: "price125", inCart: false, level: 2, image: Doxology, text: "Critical Doxology & Timenergy", title: "Analyzes the dynamics of belief systems and the concept of temporal energy in modern contexts." },
        { price: "price126", inCart: false, level: 2, image: Illich, text: "Introduction to Ivan Illich", title: "A primer on Illich's critical theories about modern institutions and their societal impacts." },
        { price: "price127", inCart: false, level: 2, image: JacqueLacan, text: "Jacque Lacan's Seminar 11", title: "An introduction to Lacan's influential theories on psychoanalysis and the unconscious mind." },
        { price: "price128", inCart: false, level: 2, image: Marxism, text: "Introduction to Marxism", title: "Covers the foundational ideas of Marxism, focusing on class struggle and economic systems." },
        { price: "price129", inCart: false, level: 2, image: Nickland, text: "Intro to Nick Land", title: "An overview of Nick Land's philosophy, including accelerationism and speculative realism." },
        { price: "price120", inCart: false, level: 2, image: PMC, text: "PMC Consciousness", title: "Explores the professional-managerial class and its influence on contemporary culture and politics." },
        { price: "price132", inCart: false, level: 2, image: Zizek, text: "Introduction to Zizek", title: "Introduces Zizek's blend of philosophy, psychoanalysis, and cultural critique." },
        { price: "price133", inCart: false, level: 1, image: BenjaminStudebaker, text: "The Benjamin Studebaker Course", title: "Delves into political philosophy and contemporary socio-economic challenges." },
        { price: "price134", inCart: false, level: 1, image: DavidMcKerracher, text: "The David McKerracher Course", title: "Examines philosophical perspectives on existence, time, and meaning." },
        { price: "price135", inCart: false, level: 1, image: MichaelDowns, text: "The Michael Downs Course", title: "Explores the relationship between belief systems and cultural dynamics." },
        { price: "price136", inCart: false, level: 1, image: NinaPower, text: "The Nina Power Course", title: "Focuses on the critical analysis of institutions and social theory." },
        { price: "price137", inCart: false, level: 1, image: TUBASEBRAID, text: "THE BASE BRAID", title: "A study of Lacanian psychoanalysis and its application in modern contexts." },
    ])

    const [courseCards, setCourseCards] = useState()

    useEffect(() => {
        if (!courseCards) {
            setCourseCards(buildCourseData(courseData))
        }
    }, [])

    useEffect(() => {
        setCourseCards(buildCourseData(courseData))
    }, [courseData])

    const buildCourseData = (data) => {
        return data.map((course, index) => [
            { inCart: course.inCart, level: course.level, priceId: course.price },
            <Image key={`image-${index}`} initialConfig={imageConfig} children={[{ src: course.image }]} />,
            <Text key={`text-${index}`} initialConfig={textConfig} children={[course.text]} />,
            <Text
                key={`title-${index}`}
                initialConfig={{
                    variant: "paragraph",
                    position: "center",
                    size: "small",
                    topHeight: "small",
                    bottomHeight: "small",
                }}
                children={[course.title]}
            />,
            <Text
                key={`title-${index}`}
                initialConfig={{
                    variant: "paragraph",
                    size: "medium",
                    weight: "bold",
                    color: "white",
                    align: "left",
                    position: "center",
                    layout: "block",
                    gap: "small",
                    transform: "none",
                    italic: false,
                    topHeight: "medium",
                    bottomHeight: "medium",
                    custom: "bg-blue-500 p-2 rounded-lg",
                }}
                children={["Add to cart"]}
            />,
        ])
    }

    const handleCardClick = (price) => {
        setCourseData((prevData) => {
            return prevData.map((course) => {
                if (course.price === price.priceId) {
                    return { ...course, inCart: !course.inCart }
                }
                return course
            })
        })
    }
    const CheckoutButton = ({ courseData }) => {
        const [showButton, setShowButton] = useState(false);
        const [prevCount, setPrevCount] = useState(0);
    
        useEffect(() => {
            const itemsInCart = courseData.filter(course => course.inCart).length;
    
            if (itemsInCart !== prevCount) {
                setShowButton(itemsInCart > 0);
                setPrevCount(itemsInCart);
            }
        }, [courseData, prevCount]);
    
        return (
            <div
                className={`sticky bottom-10 right-0 bg-blue-500 text-white p-4 rounded-full shadow-lg text-lg font-semibold z-50 cursor-pointer transition-opacity duration-300 ease-in-out ${showButton ? 'opacity-100' : 'opacity-0'}`}
            >
                Checkout
            </div>
        );
    };
    
	
    const renderCards = (level) => {
        return (
            <div className="flex flex-wrap justify-center items-stretch gap-8">
                {courseCards &&
                    courseCards.map((card, index) => {
                        let cardComponents = card.slice(1, card.length)
                        if (card[0].level == level) {
                            return (
                                <div key={index} className="relative flex-initial basis-[22%] min-w-[220px] max-w-[22%] flex-grow h-full" onClick={() => handleCardClick(card[0])}>
                                    {card[0].inCart && (
                                        <div className="absolute inset-0 bg-green-400 bg-opacity-60 flex justify-center items-center text-white z-10 rounded-lg">
                                            <div className="flex items-center space-x-2 bg-green-600 bg-opacity-95 p-4 rounded-lg">
                                                <p className="text-xl font-semibold">In Cart</p>
                                            </div>
                                        </div>
                                    )}
                                    <Card initialConfig={cardConfig} children={[cardComponents]} />
                                </div>
                            )
                        }
                        return null
                    })}
            </div>
        )
    }

    return (
        <Default>
            {/* <Text
                initialConfig={{
                    variant: "paragraph",
                    size: "xl3",
                    weight: "bold",
                    position: "center",
                    bottomHeight: "large",
                    topHeight: "medium",
                }}
                children={["Subscribers during 2025 get access to all of these:"]}
            ></Text>
            {renderCards(1)}
            <div className="pt-12"></div>
            <Text
                initialConfig={{
                    variant: "paragraph",
                    size: "xl2",
                    weight: "bold",
                    position: "center",
                    bottomHeight: "large",
                    topHeight: "medium",
                }}
                children={["Some of the past courses at TU are available to subscribers, whereas others are unavailable until the new system is fully in place. Some of those past courses include:"]}
            ></Text>
            {renderCards(2)}
            <div className="pb-8"></div>

			<CheckoutButton courseData={courseData} /> */}
            <Text
                initialConfig={{
                    variant: "paragraph",
                    size: "xl2",
                    weight: "bold",
                    position: "center",
                    bottomHeight: "large",
                    topHeight: "medium",
                }}
                children={["This page is currently under construction, check back later!"]}
            ></Text>

        </Default>
    )
}

Courses.propTypes = {}

const connection = connect((state) => ({}), {})(Courses)
export { connection as Courses }
