import React, {Children, Fragment, useEffect, useState} from "react"
import {userActions} from "../../Actions"
import {connect} from "react-redux"
import PropTypes from "prop-types"

import {Default} from "../GeneralComponents/Layouts"
import {Card, Image, Text} from "../GeneralComponents/Blocks"
import construction from "../../Images/construction.jpg"

const Staff = ({}) => {
	const [textConfig, setTextConfig] = useState({
		variant: "paragraph",
		size: "medium",
		weight: "bold",
		color: "default",
		align: "center",
		position: "center",
		layout: "block",
		gap: "small",
		transform: "none",
		italic: false,
		topHeight: "medium",
		bottomHeight: "xs",
	})
	const [imageConfig, setImageConfig] = useState({
		shape: "circle",
		size: "medium",
		stylePreset: "default",
		position: "center",
		margin: "none",
		layout: "inline",
		gap: "small",
	})
	const [cardConfig, setCardConfig] = useState({
		variant: "default",
		padding: "small",
		width: "auto",
		borderRadius: "medium",
		shadow: "default",
		background: "white",
		position: "center",
		gap: "medium",
		equalWidth: true,
	})

	const [profileData, setProfileData] = useState([
		{image: construction, text: "John Doe", title: "Lead Developer", description: "This person does ABC and is important because XYZ"},
		{image: construction, text: "Jane Smith", title: "Marketing Specialist", description: "This person does ABC and is important because XYZ"},
		{image: construction, text: "Emily Brown", title: "Project Manager", description: "This person does ABC and is important because XYZ"},
		{image: construction, text: "Michael Johnson", title: "UX/UI Designer", description: "This person does ABC and is important because XYZ"},
		{image: construction, text: "Sarah Davis", title: "Content Writer", description: "This person does ABC and is important because XYZ"},
		{image: construction, text: "Chris Wilson", title: "IT Support Technician", description: "This person does ABC and is important because XYZ"},
	])

	const [profileCards, setProfileCards] = useState()

	useEffect(() => {
		if (!profileCards) {
			setProfileCards(buildProfiles())
		}
	}, [])

	const buildProfiles = () => {
		return profileData.map((profile, index) => [
			<Image
				key={`image-${index}`}
				initialConfig={imageConfig}
				children={[{src: profile.image}]}
			/>,
			<Text
				key={`text-${index}`}
				initialConfig={textConfig}
				children={[profile.text]}
			/>,
			<Text
				key={`title-${index}`}
				initialConfig={{
					variant: "paragraph",
					position: "center",
					size: "small",
					topHeight: "none",
					bottomHeight: "small",
				}}
				children={[profile.title]}
			/>,
			<Text
				key={`desc-${index}`}
				initialConfig={{
					variant: "paragraph",
					position: "center",
					size: "xs",
					topHeight: "none",
					bottomHeight: "small",
					align: "center",

				}}
				children={[profile.description]}
			/>,
		])
	}

	return (
		<Default>
			<Text
				initialConfig={{
					variant: "paragraph",
					size: "xl3",
					weight: "bold",
					position: "center",
					bottomHeight: "large",
					topHeight: "medium",
				}}
				children={["Meet the Team"]}
			></Text>
			<Card
				initialConfig={cardConfig}
				children={profileCards}
			></Card>
			<div className="pt-12"></div>
		</Default>
	)
}

Staff.propTypes = {}

const connection = connect((state) => ({}), {})(Staff)
export {connection as Staff}
